import styled from "styled-components";
import React, {Component} from "react";
import GetToken from "../../../logic/GetToken/GetToken";
import config from "../../../config.json";
import {
    AdminPanelSeparator,
    AdminPanelTable,
    AdminPanelTd,
    AdminPanelTh,
    AdminPanelTr
} from "../AdminPanelTable/AdminPanelTable";
import InputText from "../../Assets/InputText/InputText";
import CTA from "../../Assets/CTA/CTA";
import ButtonOutline from "../../Assets/ButtonOutline/ButtonOutline";
import Clearfix from "../../Base/Clearfix/Clearfix";
import ButtonNormal from "../../Assets/ButtonNormal/ButtonNormal";
import LegendIcon from "../../Assets/LegendIcon/LegendIcon";
import DrinkIcon from "../../Assets/DrinkIcon/DrinkIcon";

const Container = styled.div`
  width: 100%;
  height: 100%;
  float: left;
`;

const NavigationContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 72px;
  float: left;
`;

const NavigationContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 40px;
`;

const TableContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0 30px;
  overflow-x: auto;
`

class AdminManageProductsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelectedIndex: 0,
            options: ["PIZZE ROSSE", "PIZZE BIANCHE", "PIZZE SPECIALI", "STARTERY", "DESERY", "NAPOJE"],
            products: []
        };

        this.loadProducts();
    }

    loadProducts() {
        fetch(`${config.backendServer}/api/v1/products?token=${GetToken()}`, {
            method: "GET"
        }).then(result => result.json())
        .then(result => {
            if (result.status === "success") {
                this.setState({products: result.products})
            }
        })
    }

    reloadProducts() {
        this.setState({products: []}, () => {
            this.loadProducts();
        });
    }

    selectOption(optionIndex) {
        this.setState({
            optionSelectedIndex: optionIndex
        })
    }

    toggleProductVisibility(productId) {
        let formData = new FormData();
        formData.append('productId', productId);
        fetch(`${config.backendServer}/api/v1/product_visibility?token=${GetToken()}`, {
            method: "PUT",
            body: formData
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    //alert('sukces')
                    this.reloadProducts();
                }
            })
    }

    deleteProduct(productId) {
        let isRequestConfirmed = window.confirm("Czy na pewno chcesz usunąć produkt" + productId + "?");
        if (isRequestConfirmed) {
            let formData = new FormData();
            formData.append('productId', productId);
            fetch(`${config.backendServer}/api/v1/product?token=${GetToken()}`, {
                method: "DELETE",
                body: formData
            }).then(result => result.json())
                .then(result => {
                    if (result.status === "success") {
                        //alert('sukces')
                        this.reloadProducts();
                    }
                })
        }
    }

    render() {
        return(
            <Container>
                <div>

                    <NavigationContainer>
                        <NavigationContainerWrapper>
                            {
                                this.state.options.map((option, index) => (
                                    <>
                                        {this.state.optionSelectedIndex === index
                                            ? <ButtonNormal>{option}</ButtonNormal>
                                            : <ButtonOutline onClick={() => this.selectOption(index)}>{option}</ButtonOutline>
                                        }
                                        {
                                            (index + 1)%3 === 0 && index > 0
                                                ? <FlexBreak />
                                                : ""
                                        }
                                    </>
                                ))
                            }
                        </NavigationContainerWrapper>
                    </NavigationContainer>
                    <Clearfix />
                    <TableContainer>
                        <AdminPanelTable>
                            <AdminPanelTr>
                                <AdminPanelTh responsive>ID</AdminPanelTh>
                                <AdminPanelTh responsive>Nazwa</AdminPanelTh>
                                <AdminPanelTh responsive>{this.state.optionSelectedIndex < 5 ? "Zdjęcie" : "Ikona"}</AdminPanelTh>
                                <AdminPanelTh responsive>Dodano</AdminPanelTh>
                                <AdminPanelTh responsive>Widoczność</AdminPanelTh>
                                <AdminPanelTh responsive style={{minWidth: 160}}>Etykiety</AdminPanelTh>
                                <AdminPanelTh responsive>Akcje</AdminPanelTh>
                            </AdminPanelTr>
                            {
                                this.state.products.map(product =>
                                    product.categoryId === this.state.optionSelectedIndex
                                    ?
                                    (
                                    <AdminPanelTr>
                                        <AdminPanelTd responsive>{product.id}</AdminPanelTd>
                                        <AdminPanelTd responsive>{product.name}</AdminPanelTd>
                                        <AdminPanelTd responsive>
                                            {
                                                this.state.optionSelectedIndex < 5
                                                ?
                                                    <img width={128} src={config.backendServer + "/public/product_files/" + product.photo} />
                                                :
                                                    <DrinkIcon iconIndex={product.icon} />
                                            }
                                        </AdminPanelTd>
                                        <AdminPanelTd responsive>{product.added.slice(0, 10)}</AdminPanelTd>
                                        <AdminPanelTd responsive style={{textAlign: "center"}}>
                                            {
                                                product.isVisible
                                                ? <span style={{color: "#146E55"}}>Widoczny</span>
                                                : <span style={{color: "#DA4A4A"}}>Ukryty</span>
                                            }
                                        </AdminPanelTd>
                                        <AdminPanelTd responsive>
                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
                                                <LegendIcon type="spicy" grayscale={!product.isSpicy} />
                                                <LegendIcon type="vege" grayscale={!product.isVege} />
                                            </div>
                                        </AdminPanelTd>
                                        <AdminPanelTd responsive>
                                            <ButtonNormal style={{marginTop: 8, marginBottom: 8, width: "150px"}} onClick={() => {this.props.setEditingProductId(product.id); this.props.setSection(0)}}>Edytuj</ButtonNormal>
                                            <Clearfix />
                                            <ButtonNormal style={{marginTop: 8, marginBottom: 8, width: "150px", backgroundColor: "#C4C4C4"}} onClick={() => this.toggleProductVisibility(product.id)}>
                                                {product.isVisible ? "Ukryj" : "Pokaż"}
                                            </ButtonNormal>
                                            <Clearfix />
                                            <ButtonNormal style={{marginTop: 8, marginBottom: 8, width: "150px", backgroundColor: "#DA4A4A"}} onClick={() => this.deleteProduct(product.id)}>Usuń</ButtonNormal>
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                    )
                                    : ""
                                )
                            }

                        </AdminPanelTable>
                    </TableContainer>
                </div>
            </Container>
        )
    }
}

export default AdminManageProductsSection;