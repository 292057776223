import styled from "styled-components";

import IndexHistoryPhotoMobile from './IndexHistoryPhotoMobile.png';
import WavePhoto from './Wave.png';
import Clearfix from "../../Base/Clearfix/Clearfix";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

import Aos from 'aos';
import "aos/dist/aos.css"
import {useEffect} from "react";
import Wave from "../../Assets/Wave/Wave";

import DecorationImg from "./Decoration.png";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  //min-height: 200vh;
  position: relative;
  padding-top: 36px;
  float: left;

  @media (min-width: 1101px) {
    display: none;
  }
`

const Photo = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 10% 10%;
  float: left;

  @media (max-width: 700px) {
    object-position: 50% 0%;
  }

  @media (min-width: 701px) {
    height: 100vh;
  }
`;

const Title = styled.h1`
  font-size: 44px;
  color: #146E55;
`;

const P = styled.p`
  line-height: 19px;
  font-size: 13px;

  @media (min-width: 701px) {
    font-size: 18px;
  }
`;

const DecorationImage = styled.img`
  position: absolute;
  top: 140px;
  left: 0;
  width: 67%;
  height: 450px;
  z-index: 5;  
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  //min-height: calc(100vh - 36px - 20px - 14px + 40px);
  margin: 40px 0;
  margin-bottom: 80px;
  //background-color: #61dafb;
`;

const IndexPageHistorySectionMobile = props => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    },[])
    return (
        <>
            <Container>
                <Wave width="100%" style={{marginLeft: 20, marginBottom: 20}}></Wave>
                <TextContainer>
                    <DefaultPaddingBox style={{}}>
                        {/*<Title data-aos="fade-up">NASZA HISTORIA...</Title>*/}
                        <SectionTitle data-aos="fade-up">NASZA HISTORIA...</SectionTitle>
                        <P data-aos="fade-up">
                            Duża łyżka marzeń, porcja odwagi, szczypta przeciwności losu i masa miłości powiązanych ze sobą pozwoliła nam stworzyć to miejsce. Restaurację z otwartością na wszystkich, doprawiając domową atmosferą i przytulnym wnętrzem. Wielka chęć zarażenia innych włoską jakością jedzenia, dały początek drodze, która nieustannie przynosi nam mnóstwo radości i satysfakcji.
                        </P>
                        <Clearfix />
                        <P data-aos="fade-up">
                            Mąkę, drożdże i sól codziennie zamieniamy w radość serwowania pizzy, która uwodzi smakiem i swoją prostotą. Uważamy, że doskonały produkt obroni się sam, nie potrzebuje wielu dodatków ani wielu przypraw. I na takich produktach budujemy naszą prostą w składniki, ale bogatą w doznania kulinarne kuchnię, hołdując produktom najwyższej jakości. Nieustannie staramy się, by wszyscy nasi Goście czuli się z nami i u nas jak w domu.
                        </P>
                    </DefaultPaddingBox>
                </TextContainer>
                <Photo src={IndexHistoryPhotoMobile} />
            </Container>
        </>
    )
}

export default IndexPageHistorySectionMobile;