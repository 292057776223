import styled from "styled-components";
import React, {Component} from "react";
import GetToken from "../../../logic/GetToken/GetToken";
import config from "../../../config.json";
import {Navigate} from "react-router-dom";
import Title from "../../Assets/Title/Title";
import {
    AdminPanelSeparator,
    AdminPanelTable,
    AdminPanelTd,
    AdminPanelTh,
    AdminPanelTr
} from "../AdminPanelTable/AdminPanelTable";
import InputText from "../../Assets/InputText/InputText";
import Clearfix from "../../Base/Clearfix/Clearfix";
import CTA from "../../Assets/CTA/CTA";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
`;

class AdminChangePasswordSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            passwordInput: "",
            repeatPasswordInput: "",
            isFeedback: false,
            feedbackMessage: ""
        };
    }

    componentDidMount() {
        this.loadCurrentPassword();
    }

    handleInputChange(event, inputName) {
        if (inputName === "password") {
            this.setState({passwordInput: event.target.value});
        } else if (inputName === "repeatPassword") {
            this.setState({repeatPasswordInput: event.target.value});
        }
    }

    loadCurrentPassword() {
        fetch(`${config.backendServer}/api/v1/password?token=${GetToken()}`, {
            method: "GET"
        }).then(result => result.json())
        .then(result => {
            if (result.status === "success") {
                this.setState({currentPassword: result.password})
            }
        })
    }

    changePassword() {
        if (this.state.passwordInput !== this.state.repeatPasswordInput) return alert("Hasła nie są identyczne!");

        const newPassword = this.state.passwordInput;
        const token = GetToken();

        fetch(`${config.backendServer}/api/v1/change_password`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({token: token, newPassword: newPassword})
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    this.setState({isFeedback: true, feedbackMessage: "Hasło zmieniono pomyślnie."})
                    this.loadCurrentPassword();
                } else {
                    this.setState({isFeedback: true, feedbackMessage: "Wystąpił błąd - hasło nie zostało zmienione!"})
                }
            })
    }

    render() {
        return(
            <Container>
                {
                    !this.state.isFeedback
                    ?
                        <div>
                            <AdminPanelTable>
                                <AdminPanelTr>
                                    <AdminPanelTh style={{width: "300px"}}>Aktualne hasło</AdminPanelTh>
                                    <AdminPanelTh style={{width: "400px"}}>Wprowadź nowe hasło</AdminPanelTh>
                                </AdminPanelTr>
                                <AdminPanelTr>
                                    <AdminPanelTd style={{width: "300px", textAlign: "center"}}>
                                        <i>{this.state.currentPassword}</i>
                                        <AdminPanelSeparator />
                                    </AdminPanelTd>
                                    <AdminPanelTd style={{width: "400px"}}>
                                        <InputText type="password" style={{width: "100%", margin: "10px 0"}} placeholder="Nowe hasło" onChange={(e) => this.handleInputChange(e, "password")} />
                                        <InputText type="password" style={{width: "100%", margin: "10px 0"}} placeholder="Powtórz nowe hasło" onChange={(e) => this.handleInputChange(e, "repeatPassword")} />
                                    </AdminPanelTd>
                                </AdminPanelTr>
                            </AdminPanelTable>
                            <CTA style={{float: "right", marginTop: "60px"}} noMargins onClick={() => this.changePassword()}>ZMIEŃ HASŁO</CTA>
                        </div>
                    :
                        <div>
                            <AdminPanelTable>
                                <AdminPanelTr>
                                    <AdminPanelTh>Wiadomość zwrotna</AdminPanelTh>
                                </AdminPanelTr>
                                <AdminPanelTr>
                                    <AdminPanelTd>{this.state.feedbackMessage}</AdminPanelTd>
                                </AdminPanelTr>
                            </AdminPanelTable>
                        </div>
                }
            </Container>
        )
    }
}

export default AdminChangePasswordSection;