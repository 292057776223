import styled from "styled-components";
import WelcomeBackgroundImg from "./WelcomeBackground.png";
import WelcomeTitleImg from "./WelcomeTitle.svg";
import WelcomeTitleMobileImg from "./WelcomeTitleMobile.svg";
import CTA from "../../Assets/CTA/CTA";
import {Link} from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  float: left;
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  float: left;
`;

const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  text-align: center;

  @media (max-width: 1100px) {
    width: calc(100vw - 120px);
  }
`;

const WelcomeTitleImage = styled.img`
  width: 600px;
  filter: invert(1);

  @media (max-width: 1100px) {
    display: none;
  }
`;

const WelcomeTitleMobileImage = styled.img`
  //width: 210px;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  
  filter: invert(1);

  @media (min-width: 1101px) {
    display: none;
  }

  @media (max-width: 700px) {
    max-width: 232px;
    margin-bottom: 0;
  }
`;

const P = styled.p`
  font-size: 22px;
  color: white;

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  top: calc(50% + 112px);
  left: 50%;
  transform: translateX(-50%);
  min-width: 256px;
  min-height: 92px;
  padding: 12px 4px;
  //border: 2px dashed #E5E5E5;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23E5E5E5FF' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  @media (min-width: 700px) and (max-width: 1100px) {
    top: calc(50% + 152px);
  }

  

  @media (max-width: 640px) {
    min-width: 256px;
    
    &:hover {
      width: 267px;
    }
  }
`;

const IndexPageWelcomeSection = props => {
    return (
        <Container>
            <BackgroundImage src={WelcomeBackgroundImg} />
            <CenteredContainer>
                <WelcomeTitleImage src={WelcomeTitleImg} />
                <WelcomeTitleMobileImage src={WelcomeTitleMobileImg} />
                <P>...przez pizzę do serca</P>
                <ButtonContainer>
                    <Link to="/menu">
                        <CTA style={{filter: "none"}}>POZNAJ MENU</CTA>
                    </Link>
                </ButtonContainer>
            </CenteredContainer>

        </Container>
    )
}

export default IndexPageWelcomeSection;