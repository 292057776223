import styled from "styled-components";

import IndexHistoryPhoto from './IndexHistoryPhoto.jpg';
import WavePhoto from './Wave.png';
import Clearfix from "../../Base/Clearfix/Clearfix";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

import Aos from 'aos';
import "aos/dist/aos.css"
import {useEffect} from "react";
import Wave from "../../Assets/Wave/Wave";

import DecorationImg from "./Decoration.png";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  float: left;

  @media (max-width: 1100px) {
    display: none;
  }

  @media (min-width: 1100px) {
    min-height: 900px;
  }
`

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  margin-top: 80px;
  float: left;

  @media (max-width: 1200px) {

  }
`;

const LeftSectionWrapper = styled.div`
  position: absolute;
  top: 40px; // to not cross with wave
  left: 0;
  width: 100%;
  height: calc(100% - 160px - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  @media (max-width: 1200px) {
    
  }

  @media (max-width: 1200px) {
    
    //height: calc(100% - 160px);
  }
`;

const RightSection = styled.div`
  width: 50%;
  height: 100%;
  //background-color: #61dafb;
  overflow: hidden;
  float: left;


`;

const Photo = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 25% 25%;
  float: right;

  @media (min-width: 1100px) {
    min-height: 900px;
  }
`;

const Title = styled.h1`
  font-size: 44px;
  color: #146E55;
`;

const P = styled.p`
  line-height: 27px;
  font-size: 18px;

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const DecorationImage = styled.img`
  position: absolute;
  top: 140px;
  left: 0;
  width: 67%;
  height: 450px;
  z-index: 5;  
`;

const IndexPageHistorySection = props => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    },[])
    return (
        <>
            <Container>
                    <DecorationImage src={DecorationImg} />
                    <LeftSection>
                        <Wave src={WavePhoto} draggable="false" />
                        <LeftSectionWrapper>
                            <>
                                <DefaultPaddingBox>
                                {/*<Title data-aos="fade-up">NASZA HISTORIA...</Title>*/}
                                <SectionTitle data-aos="fade-up">NASZA HISTORIA...</SectionTitle>
                                <P data-aos="fade-up">
                                    Duża łyżka marzeń, porcja odwagi, szczypta przeciwności losu i masa miłości powiązanych ze sobą pozwoliła nam stworzyć to miejsce. Restaurację z otwartością na wszystkich, doprawiając domową atmosferą i przytulnym wnętrzem. Wielka chęć zarażenia innych włoską jakością jedzenia, dały początek drodze, która nieustannie przynosi nam mnóstwo radości i satysfakcji.
                                </P>
                                <Clearfix />
                                <P data-aos="fade-up">
                                    Mąkę, drożdże i sól codziennie zamieniamy w radość serwowania pizzy, która uwodzi smakiem i swoją prostotą. Uważamy, że doskonały produkt obroni się sam, nie potrzebuje wielu dodatków ani wielu przypraw. I na takich produktach budujemy naszą prostą w składniki, ale bogatą w doznania kulinarne kuchnię, hołdując produktom najwyższej jakości. Nieustannie staramy się, by wszyscy nasi Goście czuli się z nami i u nas jak w domu.
                                </P>
                                </DefaultPaddingBox>
                            </>
                        </LeftSectionWrapper>
                    </LeftSection>
                    <RightSection data-aos="fade-left">
                        <Photo src={IndexHistoryPhoto} />
                    </RightSection>
            </Container>
        </>
    )
}

export default IndexPageHistorySection;