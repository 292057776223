import React, {Component} from "react";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import styled from "styled-components";
import PizzaElement from "./PizzaElement/PizzaElement";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

import Aos from 'aos';
import "aos/dist/aos.css"

const Container = styled.div`
  width: 100%;
  height: auto;
  float: left;
`;

const RowContainer = styled.div`
  width: 100%;
  height: auto;
  //background-color: plum;
  //border: 1px solid gray;
  float: left;
`

const DesktopPizzaList = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const MobilePizzaList = styled.div`
  @media (min-width: 1101px) {
    display: none;
  }
`;


class MenuPagePizzasSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            mobileSections: []
        };
        //console.log(this.props.products)
    }

    isUpdated = false;

    componentDidMount() {
        this.generateSections();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.isUpdated) {

            this.generateSections();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.isUpdated = false;
    }

    generateSections() {
        let sectionIndex = -1;
        let sections = [];
        for (let i = 0; i < this.props.products.length; i++) {
            if (i % 3 === 0) {
                sectionIndex++;
                sections.push([]);
            }
            sections[sectionIndex].push(this.props.products[i]);
        }

        let mobileSectionIndex = -1;
        let mobileSections = [];
        for (let i = 0; i < this.props.products.length; i++) {
            if (i % 2 === 0) {
                mobileSectionIndex++;
                mobileSections.push([]);
            }
            mobileSections[mobileSectionIndex].push(this.props.products[i]);
        }

        if (sections.length > 0)  {
            this.setState({sections: sections, mobileSections: mobileSections});
            this.isUpdated = true;
        }
    }

    render() {
        return <ContentContainer>
            <DefaultPaddingBox>
                <Container>
                    <DesktopPizzaList>
                        {
                            this.state.sections.map(
                                (section, index) => (
                                    <RowContainer key={Math.random().toString()}>
                                        {section.map(
                                            (product, index) => (
                                                <PizzaElement productData={product} horizontalMargins={index%3 === 1} />
                                            )
                                        )}
                                    </RowContainer>
                                )
                            )
                        }
                    </DesktopPizzaList>
                    <MobilePizzaList>
                        {
                            this.state.mobileSections.map(
                                (section) => (
                                    <RowContainer key={Math.random().toString()}>
                                        {section.map(
                                            (product, index) => (
                                                <PizzaElement productData={product} />
                                            )
                                        )}
                                    </RowContainer>
                                )
                            )
                        }
                    </MobilePizzaList>
                    {/*<PizzaElement />*/}
                    {/*<PizzaElement horizontalMargins={true} />*/}
                    {/*<PizzaElement />*/}
                    {/*<PizzaElement />*/}
                </Container>
            </DefaultPaddingBox>
        </ContentContainer>
    }
}

export default MenuPagePizzasSection;