import React, {Component} from "react";
import styled from "styled-components";
import Clearfix from "../../Base/Clearfix/Clearfix";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import MenuSlate from "../MenuSlate/MenuSlate";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 70px 0;
  float: left;
`;

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  padding-right: 27px;
  float: left;

  @media (max-width: 1100px) {
    width: 100%;
    padding-right: 0;
  }
`;

const RightSection = styled.div`
  width: 50%;
  height: 100%;
  padding-left: 27px;
  float: left;

  @media (max-width: 1100px) {
    width: 100%;
    padding-left: 0;
  }
`;

class MenuPageDrinksSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesLeftSection: [
                {name: "KOKTAJLE KLASYCZNE", styleIndex: 0, withDescriptions: true, withIcons: true, products: []},
                {name: "SPECJAŁY NASZEGO BARU", styleIndex: 0, withDescriptions: true, withIcons: true, products: []},
                {name: "PIWA", styleIndex: 2,  withDescriptions: true, withIcons: false, products: []}
            ],
            categoriesRightSection: [
                {name: "KOKTAJLE BEZ ALKOHOLU", styleIndex: 1, withDescriptions: true, withIcons: true, products: []},
                {name: "DOMOWE LEMONIADY", styleIndex: 1, withDescriptions: false, withIcons: true, products: []},
                {name: "NAPOJE ZIMNE", styleIndex: 0, withDescriptions: false, withIcons: false, products: []},
                {name: "KAWY", styleIndex: 2, withDescriptions: false, withIcons: false, products: []},
                {name: "HERBATY", styleIndex: 2, withDescriptions: false, withIcons: false, products: []},
            ]
        };

        this.assignProductsToSlates();
    }

    assignProductsToSlates() {
        console.log(this.props.products)
        for (let i = 0; i < this.props.products.length; i++) {
            for (let j = 0; j < this.state.categoriesLeftSection.length; j++) {
                if (this.props.products[i].tag === this.state.categoriesLeftSection[j].name) {
                    this.state.categoriesLeftSection[j].products.push(this.props.products[i]);
                    break;
                }
            }
            for (let j = 0; j < this.state.categoriesRightSection.length; j++) {
                if (this.props.products[i].tag === this.state.categoriesRightSection[j].name) {
                    this.state.categoriesRightSection[j].products.push(this.props.products[i]);
                }
            }
        }
    }

    render() {
        return <ContentContainer>
            <Container>
                <DefaultPaddingBox>
                    <LeftSection>
                        {
                            this.state.categoriesLeftSection.map(
                                slate => (
                                    <MenuSlate title={slate.name} styleIndex={slate.styleIndex} withDescriptions={slate.withDescriptions} withIcons={slate.withIcons} products={slate.products} />
                                )
                            )
                        }
                    </LeftSection>
                    <RightSection>
                        {
                            this.state.categoriesRightSection.map(
                                slate => (
                                    <MenuSlate title={slate.name} styleIndex={slate.styleIndex} withDescriptions={slate.withDescriptions} withIcons={slate.withIcons} products={slate.products} />
                                )
                            )
                        }
                    </RightSection>
                    {/*<LeftSection>*/}
                    {/*    <MenuSlate title="KOKTAJLE KLASYCZNE" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}, {name: "MOJITO", description: "Rum Bacardi Blanca, limonka, mięta, syrop cukrowy, woda gazowana"}]} />*/}
                    {/*    <MenuSlate title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}, {name: "MOJITO", description: "Rum Bacardi Blanca, limonka, mięta, syrop cukrowy, woda gazowana"}]} />*/}
                    {/*    <MenuSlate styleIndex={2} title="PIWA" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}, {name: "MOJITO", description: "Rum Bacardi Blanca, limonka, mięta, syrop cukrowy, woda gazowana"}]} />*/}
                    {/*</LeftSection>*/}
                    {/*<RightSection>*/}
                    {/*    <MenuSlate styleIndex={1} title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}]} />*/}
                    {/*    <MenuSlate styleIndex={1} title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}]} />*/}
                    {/*    <MenuSlate styleIndex={0} title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}]} />*/}
                    {/*    <MenuSlate styleIndex={2} title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}]} />*/}
                    {/*    <MenuSlate styleIndex={2} title="SPECJAŁY NASZEGO BARU" withDescriptions={true} withIcons={true} products={[{name: "OLD CUBAN", description: "Rum Bacardi Black, prosecco, mięta, limonka, syrop cukrowy, angostura bitters"}, {name: "Moscow Mule", description: "Wódka Ostoya, limonka, ginger beer, mięta"}]} />*/}
                    {/*</RightSection>*/}
                </DefaultPaddingBox>
            </Container>
        </ContentContainer>
    }
}

export default MenuPageDrinksSection;