import React, {Component} from "react";
import Header from "../../components/Header/Header";
import Clearfix from "../../components/Base/Clearfix/Clearfix";
import MenuPageNavigation from "../../components/MenuPage/MenuPageNavigation/MenuPageNavigation";
import ContentContainer from "../../components/Base/ContentContainer/ContentContainer";
import Wave from "../../components/Assets/Wave/Wave";
import MenuPageDrinksSection from "../../components/MenuPage/MenuPageDrinksSection/MenuPageDrinksSection";
import FooterGallery from "../../components/FooterGallery/FooterGallery";
import Footer from "../../components/Footer/Footer";
import ContactPageContent from "../../components/ContactPage/ContactPageContent/ContactPageContent";
import ContentContainerAlternative from "../../components/Base/ContentContainerAlternative/ContentContainerAlternative";
import ScrollToTopButton from "../../components/Assets/ScrollToTopButton/ScrollToTopButton";
import styled from "styled-components";
import BodyContainer from "../../components/Base/BodyContainer/BodyContainer";
import ContactPageContentMobile from "../../components/ContactPage/ContactPageContent/ContactPageContentMobile";



class ContactView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <BodyContainer>
            <Header indexSelected={2} />
            <Clearfix />
            <ScrollToTopButton />

            <ContentContainerAlternative>
                <ContactPageContent />
                <ContactPageContentMobile />
            </ContentContainerAlternative>
            <Clearfix />

            <Wave width="780px" style={{marginTop: 80, marginBottom: 30}} />

            <FooterGallery />
            <Clearfix />
            <Footer />
        </BodyContainer>
    }
}

export default ContactView;