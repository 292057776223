import React, {Component} from "react";
import styled from "styled-components";
import Clearfix from "../../../components/Base/Clearfix/Clearfix";
import ButtonNormal from "../../../components/Assets/ButtonNormal/ButtonNormal";
import IsAdminCheck from "../../../logic/IsAdminCheck/IsAdminCheck";
import config from "../../../config.json";
import { Navigate } from "react-router-dom"
import AdminSidebar from "../../../components/Admin/AdminSidebar/AdminSidebar";
import AdminPanelPage from "../../../components/Admin/AdminPanelPage/AdminPanelPage";
import ScrollToTopButton from "../../../components/Assets/ScrollToTopButton/ScrollToTopButton";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  float: left;
`;

class AdminPanelView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLogged: false
        };

        IsAdminCheck.check().then(isAdmin => {
            console.log("isAdmin: " + isAdmin)
            if (isAdmin) {
                this.setState({isLogged: true, isLoaded: true});
            } else {
                this.setState({isLogged: false, isLoaded: true});
            }
        })
    }

    render() {
        return <Container>
            {this.state.isLoaded && !this.state.isLogged && <Navigate to='/admin/login' replace={true} />}
            <AdminPanelPage />
            <ScrollToTopButton />
        </Container>
    }
}

export default AdminPanelView;