import {Component} from "react";
import styled from "styled-components";

import Aos from 'aos';
import "aos/dist/aos.css"

import ArrowImg from "./Arrow.png";

const Button = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  
  font-style: normal;
  font-weight: normal;
  padding: 12px 28px;
  font-size: 18px;
  color: white;
  line-height: 23px;
  text-align: center;
  background-color: #146E55;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 10px rgba(3, 71, 58, 0.5);
  
  z-index: 50;
  transition: background-color 0.5s;
  cursor: pointer;
  
  &:hover {
    background-color: #075C48;
  }
`;

const ArrowImage = styled.img`
  
`;

class ScrollToTopButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollX: 0,
            scrollY: 0
        };

        window.addEventListener('scroll', () => this.setState({scrollX: window.scrollX, scrollY: window.scrollY}));
    }

    render() {
        return (
            <>
            {
                this.state.scrollY > 300
                    ?
                    <Button data-aos="fade-up" onClick={() => window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })}>
                        <ArrowImage src={ArrowImg} />
                    </Button>
                    :
                    ""
            }
            </>
        );
    }
}

export default ScrollToTopButton;