import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import styled from "styled-components";

import Photo from './IndexPhoto2Mobile.png';
import Title from "../../Assets/Title/Title";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";
import Wave from "../../Assets/Wave/Wave";
import CallLabel from "../../InfoLabels/CallLabel/CallLabel";

import Aos from 'aos';
import "aos/dist/aos.css"
import {useEffect} from "react";

import DecorationImg from "./Decoration.png";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  //height: calc(100vh - 490px - 50px - 80px);
  height: 100vh;
  //max-height: 600px;
  //margin-bottom: 20px;
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  float: left;

  @media (min-width: 1101px) {
    display: none;
  }
`

const Img = styled.img`
  margin-top: 48px;
  width: 100%;
  height: calc(100% - 40px - 150px);
  object-fit: cover;
`;

const DecorationImage = styled.img`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  z-index: 1;
`;

const IndexPageOrderingSectionMobile = props => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    },[])
    return (
        <>
            <Container>
                <div data-aos="fade-up">
                    <DefaultPaddingBox>
                        <SectionTitle>ZAMÓWIENIA</SectionTitle>
                        <CallLabel />
                    </DefaultPaddingBox>
                    <Wave width="100%" style={{marginLeft: 20, marginTop: 0}} />
                </div>
                <Img src={Photo} />
            </Container>
        </>
    )
}

export default IndexPageOrderingSectionMobile;