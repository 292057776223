import styled from "styled-components";
import SpicyIcon from "./SpicyIcon.svg";
import VegeIcon from "./VegeIcon.svg";
import PizzaOfTheMonthIcon from "./PizzaOfTheMonthIcon.svg";

const Container = styled.div`
  width: 25px;
  height: 25px;
  margin: 0 3px;
  float: left;
`;

const Icon = styled.img`
  height: 100%;
  width: auto;
  float: left;
  
  ${props => props.grayscale ? "filter: grayscale(100%); opacity: 0.5;" : ""}
`;

const LegendIcon = props => {
    const src = (props.type === "spicy" ? SpicyIcon : (props.type === "vege" ? VegeIcon : PizzaOfTheMonthIcon));
    const title = (props.type === "spicy" ? "Spicy" : (props.type === "vege" ? "Vege" : "Pizza miesiąca"));
    return(
        <Container style={props.style} onClick={props.onClick} title={title}>
            <Icon src={src} grayscale={props.grayscale} />
        </Container>
    );
}

export default LegendIcon;