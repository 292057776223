import {useState} from "react";
import styled from "styled-components";

import InstagramIcon from "./InstagramIcon.svg";
import FacebookIcon from "./FacebookIcon.svg";
import {Link} from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 155px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  color: #E5E5E5;
  @media (max-width: 700px) {
    display: block;
    height: auto;
    padding: 19px 0;
    float: left;
  }
`

const Item = styled.div`
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    margin-bottom: 19px;
    float: left;
  }
`;

const ItemIcon = styled.img`
  width: 55px;
  height: 55px;
  transition: 0.5s;
  filter: brightness(0) invert(0.85);
  
  float: left;
  
  &:hover {
    filter: none;
  }

  @media (max-width: 700px) {
    width: 36px;
    height: 36px;
  }
`;

const ItemText = styled.div`
  margin-left: 20px;
  float: left;

  @media (max-width: 700px) {
    margin-left: 12px;
  }
`;

const ItemTitle = styled.p`
  font-size: 18px;
  margin: 2px 0;
  @media (max-width: 700px) {
    font-size: 13px;
    font-weight: 400;
  }
`;

const ItemTextValue = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 2px 0;
  @media (max-width: 700px) {
    font-size: 18px;
  }
  @media (max-width: 1100px) {
    font-size: 22px;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Footer = props => {
    return (
        <Container>
            <A href="https://www.instagram.com/palecdobudki/" target="_blank">
                <Item>
                    <ItemIcon src={InstagramIcon} />
                    <ItemText>
                        <ItemTitle>instagram:</ItemTitle>
                        <ItemTextValue>@palecdobudki</ItemTextValue>
                    </ItemText>
                </Item>
            </A>
            <A href="https://www.facebook.com/palecdobudki" target="_blank">
                <Item>
                    <ItemIcon src={FacebookIcon} />
                    <ItemText>
                        <ItemTitle>facebook:</ItemTitle>
                        <ItemTextValue>@palecdobudki</ItemTextValue>
                    </ItemText>
                </Item>
            </A>
        </Container>
    )
}

export default Footer;