import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import styled from "styled-components";

import Photo from './IndexPhoto2.png';
import Title from "../../Assets/Title/Title";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";
import Wave from "../../Assets/Wave/Wave";
import CallLabel from "../../InfoLabels/CallLabel/CallLabel";

import Aos from 'aos';
import "aos/dist/aos.css"
import {useEffect} from "react";

import DecorationImg from "./Decoration.png";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  //height: calc(100vh - 490px - 50px - 80px);
  height: 50vh;
  //max-height: 600px;
  //margin-bottom: 20px;
  position: relative;
  float: left;

  @media (max-width: 1100px) {
    display: none;
  }
`

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
`;

const RightSection = styled.div`
  width: 50%;
  height: 100%;
  padding-top: 250px;
  float: left;
`;

const RightSectionWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //background-color: #61dafb;
  z-index: 31;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DecorationImage = styled.img`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  z-index: 1;
`;

const IndexPageOrderingSection = props => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    },[])
    return (
        <>
            <Container>
                <DecorationImage src={DecorationImg} />
                <LeftSection data-aos="fade-right">
                    <Img src={Photo} />
                </LeftSection>

                <RightSection>
                    <RightSectionWrapper>
                        <div data-aos="fade-up">
                            <DefaultPaddingBox>
                                <SectionTitle>ZAMÓWIENIA</SectionTitle>
                                <CallLabel />
                            </DefaultPaddingBox>
                            <Wave width="100%" style={{marginLeft: 120, marginTop: 0}}></Wave>
                        </div>
                    </RightSectionWrapper>
                    {/*<Wave width="100%" style={{marginLeft: 120, marginTop: 0}}></Wave>*/}
                </RightSection>

            </Container>
        </>
    )
}

export default IndexPageOrderingSection;