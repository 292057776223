import styled from "styled-components";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

import SlideshowPhoto1 from "./SlideshowPhoto1.png";
import SlideshowPhoto2 from "./SlideshowPhoto2.png";
import SlideshowPhoto3 from "./SlideshowPhoto3.png";
import SlideshowPhoto4 from "./SlideshowPhoto4.png";
import SlideshowPhoto5 from "./SlideshowPhoto5.png";
import SlideshowPhoto6 from "./SlideshowPhoto6.png";
import SlideshowPhoto7 from "./SlideshowPhoto7.png";
import ArrowLeftPhoto from "./ArrowLeft.png";
import BackgroundArrowImg from "./BackgroundArrow.png";

import {Component} from "react";
import Aos from 'aos';
import "aos/dist/aos.css"

const Container = styled.div`
  width: 100%;
  height: 460px;
  background-color: #146E55;
  position: relative;
  padding-top: 71px;
  float: left;

  @media (max-width: 1100px) {
    height: auto;
    min-height: 650px;
    min-height: 100vh;
  }
`

const TopTitle = styled.div`
  width: 1100px;
  height: 150px;
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  color: #03473A;
  text-transform: uppercase;
  user-select: none;

  @media (max-width: 1100px) {
    top: -58px;

    width: calc(100% - 200px);
    font-size: 42px;
  }

  @media (max-width: 316px) {
    top: -60px;
  }
  
  @media (min-width: 317px) and (max-width: 550px) {
    width: calc(100% - 150px);
    font-size: 32px;
    top: -40px;
  }

  @media (min-width: 550px) and (max-width: 745px) {
    top: -50px;
  }

  @media (max-width: 460px) {
    width: calc(100% - 50px);
    font-size: 32px;
  }

  @media (min-width: 745px) and (max-width: 1100px) {
    top: -30px;
    width: calc(100% - 150px);
  }
`;

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-right: 40px;
  padding-bottom: 82px;
  
  float: left;

  @media (max-width: 1100px) {
    width: 100%;
    height: 50vh;
    padding-right: 0;
    padding-bottom: 32px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: calc(50vh - 82px);
    padding-right: 0;
    padding-bottom: 32px;
  }
`;

const RightSection = styled.div`
  width: 50%;
  height: calc(100% + 71px);
  color: #F0F0F0;
  position: relative;
  margin-top: -71px;
  //background-color: yellow;
  //padding-top: 50px;
  //padding-bottom: 140px;
  float: left;

  @media (max-width: 1100px) {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: plum;
    margin-top: 0;
    padding-top: 26px;
    padding-bottom: 36px;
  }
`;

const SlideshowPhoto = styled.img`
  //width: calc(100% - 16px * 2 - 36px * 2);
  height: 100%;
  float: left;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1100px) {
    max-width: 900px;
    width: calc(100% - 40px);
    height: auto;
    min-height: 170px;
  }


`;

const SlideshowArrow = styled.button`
  width: 16px;
  height: 28px;
  background-color: unset;
  border: none;
  background-image: url("${ArrowLeftPhoto}");
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 82px/2);
  ${props => props.isOnRight ? "right: 50px;" : "left: 0;"};
  transform: translateY(-50%) scaleX(${props => props.isOnRight ? "-1" : "1"});

  @media (max-width: 1100px) {
    position: static;
    ${props => props.isOnRight ? "padding-right: 5px;" : ""};
    ${props => !props.isOnRight ? "padding-left: 5px;" : ""};
    
  }

  @media (max-width: 600px) {
    position: absolute;
    top: 50%;
    ${props => props.isOnRight ? "right: 0;" : "left: 0;"}
    z-index: 10;
  }
`;

const RightSectionTitle = styled.p`
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 1100px) {
    font-size: 22px;
  }
`;

const RightSectionDescription = styled.p`
  line-height: 144%;
  @media (max-width: 1100px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 13px;
  }
`;

const RightSectionNavigationContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  width: auto;
  height: 10px;
  margin-left: -8px;
  float: left;

  @media (max-width: 1325px) {
    bottom: 36px;
  }

  @media (max-width: 1100px) {
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RightSectionNavigationItem = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${props => props.isSelected ? "#F0F0F0" : "#03473A"};
  margin: 0 8px;
  cursor: pointer;
  float: left;
  
`;

const BackgroundArrow = styled.img`
  position: absolute;
  top: 116px;
  left: 0;
  width: 100%;
  height: 236px;

  @media (max-width: 700px) {
    display: none;
  }

  @media (max-width: 1100px) {
    width: 150%;
    object-fit: cover;
  }
`;

const RightSectionDataContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1100px) {
    position: static;
    float: left;
    transform: none;
    //margin-top: 60px; ;
  }

  @media (max-width: 600px) {
    
  }
  
`

class IndexPageSlideshowSection extends Component {
    constructor() {
        super();
        this.state = {
            sectionSelectedIndex: 0,
            titles: ["Mortadella Bologna ", "BRESAOLA", "PROSCIUTTO CRUDO", "MOZZARELLA DI BUFALA CAMPANA", "POMODORO SAN MARZANO", "MĄKA Z PSZENICY MIĘKKIEJ TYP 0", "SALAMI SPIANATA PICCANTE"],
            descriptions: [
                "Jest to produkt wędliniarski należący do kategorii kiełbas gotowanych, przygotowany z mieszanki wieprzowiny. Do jej wyrobu  używa się tylko najwyższej jakości mięsa, aby uzyskać delikatną konsystencję. Posiada wyjątkowy i niezrównany aromat. Tradycyjny produkt regionu Bolonii.",
                "Bresaola to typowa (pochodząca z doliny Valtellina w Lombardii w północnym regionie Włoch), długo dojrzewająca wędlina z mięsa wołowego. Początkowo mięso przebywa w solance 10-15 dni, a następnie dojrzewa 8-12 tygodni. Mimo, że mięso dojrzewa tak długo, to dzięki temu, że odbywa się to szczególnym klimacie regionu Valtellina jest wyjątkowo miękkie i soczyste. Z czasem bresaola lekko twardnieje i zmienia kolor na ciemnoczerwony. W trakcie dojrzewania  nacierana jest kminkiem, kolendrą i innymi ziołami.  Bresaola ma delikatny, lekko słonawy smak i charakteryzuje się niską zawartością tłuszczu.",
                "Prosciutto Crudo to jedna z najlepszych propozycji zarezerwowana dla ceniących najlepszą jakość i niebywały aromat. Włoska szynka wieprzowa została wyprodukowana ze specjalnie wyselekcjonowanych udźców. Trzoda chlewna pochodząca z ekologicznych gospodarstw cechuje się najlepszej jakości mięsem, dzięki czemu wędliny są niezwykle pożywne i zdrowe. Cechą charakterystyczną dla prosciutto jest typowy różowy kolor oraz wyjątkowy aromat, którego na próżno szukać wśród innych propozycji.",
                "Ser Mozzarella di bufala Campana DOP to włoski ser z mleka bawolego, z rodziny serów podpuszczkowych. Pochodzi z południowych Włoch, regionu Kampania. Wyróżnia się większą zawartością tłuszczu i białka niż sery z mleka krowiego, dzięki czemu nabiera niepowtarzalnego, kremowego smaku. Mozzarella di bufala to świeży ser włoski o owalnym kształcie, cienkiej skórce i delikatnym smaku.",
                "Pomodoro San Marzano to pomidory uprawiane na wulkanicznej glebie regionu Agro Sarnese Nocerino leżącego między Neapolem a Salerno. Intensywny, czerwony kolor, mięsista struktura oraz podłużny kształt to znaki rozpoznawcze pomidorów San Marzano. Pomidory te wyróżniają się niezwykłym smakiem, słodszym od zwykłych pomidorów. W skrócie mówiąc, palce lizać! Znajdziecie je u nas na każdej pizzy ROSSO.",
                "Otrzymywana z mielenia w walcach włoskiej i europejskiej pszenicy certyfikowanego pochodzenia.Idealna do ciast poddawanych długiemu czasowi wzrostu oraz/lub wysokiej hydratacji, ponieważ zapewnia: optymalną kruchość (pizza nie jest gumowata, nawet jeśli jest zimna), stabilność i chrupkość struktury, również po upieczeniu: pizza utrzymuje wagę dodatków bez uginania oraz ciasto nie namaka, maksymalną strawność bazy do pizzy, idealna wydajność ciasta przez długi czas, która gwarantuje stałą jakość produktu finalnego.",
                "Pyszne włoskie pikantne salami wieprzowe wyprodukowane z mięsa najwyższej jakości. Jak wskazuje sama nazwa, salami spianata piccante jest pikantne w smaku, ale w sposób umiarkowany. Ostrość zawdzięcza dodatkowi włoskich papryczek pepperoni, które pobudzają apetyt, rozgrzewają i przyspieszają metabolizm. Spianata piccante to rarytas ceniony przez smakoszy z całej Europy"
            ],
            images: [
                SlideshowPhoto1, SlideshowPhoto2, SlideshowPhoto3, SlideshowPhoto4, SlideshowPhoto5, SlideshowPhoto6, SlideshowPhoto7
            ]
        }
        Aos.init({ duration: 1000 });
        this.touchstartX = 0
        this.touchendX = 0

        document.addEventListener('touchstart', e => {
            this.touchstartX = e.changedTouches[0].screenX
            console.log('touch start');
        })

        document.addEventListener('touchend', e => {
            this.touchendX = e.changedTouches[0].screenX
            this.checkDirection()
            console.log('touch end');
        })
    }

    checkDirection() {
        if (this.touchendX < this.touchstartX && Math.abs(this.touchendX - this.touchstartX) > 100) this.nextSlide();
        if (this.touchendX > this.touchstartX && Math.abs(this.touchendX - this.touchstartX) > 100) this.previousSlide();
    }

    nextSlide = () => {
        let newIndex = this.state.sectionSelectedIndex + 1;
        if (newIndex >= this.state.titles.length) newIndex = 0;
        this.setState({sectionSelectedIndex: newIndex})
    }

    previousSlide = () => {
        let newIndex = this.state.sectionSelectedIndex - 1;
        if (newIndex < 0) newIndex = this.state.titles.length - 1;
        this.setState({sectionSelectedIndex: newIndex})
    }

    render() {
        return (
            <Container>
                <BackgroundArrow src={BackgroundArrowImg} />
                <TopTitle>
                    TYLKO WYSOKA JAKOŚĆ!
                </TopTitle>
                <ContentContainer>
                    <DefaultPaddingBox>
                        <LeftSection>
                            <SlideshowArrow onClick={() => this.previousSlide()} />
                            {
                                this.state.images.map((image, index) => (
                                    index === this.state.sectionSelectedIndex
                                    ?
                                        <SlideshowPhoto data-aos="fade-left" src={this.state.images[this.state.sectionSelectedIndex]}/>
                                    :
                                        ""
                                ))
                            }

                            <SlideshowArrow isOnRight onClick={() => this.nextSlide()} />
                        </LeftSection>
                        <RightSection>

                            {
                                this.state.titles.map((section, index) => (
                                    (
                                        this.state.sectionSelectedIndex === index
                                            ?
                                            <RightSectionDataContainer>
                                                <RightSectionTitle data-aos="fade-right">{this.state.titles[this.state.sectionSelectedIndex]}</RightSectionTitle>
                                                <RightSectionDescription data-aos="fade-left">
                                                    {this.state.descriptions[this.state.sectionSelectedIndex]}
                                                </RightSectionDescription>
                                            </RightSectionDataContainer>
                                            : ""
                                    )
                                ))
                            }

                            <RightSectionNavigationContainer>
                                {
                                    this.state.titles.map((section, index) => (
                                        <RightSectionNavigationItem onClick={() => this.setState({sectionSelectedIndex: index})} isSelected={index === this.state.sectionSelectedIndex} />
                                    ))
                                }
                            </RightSectionNavigationContainer>

                        </RightSection>
                    </DefaultPaddingBox>
                </ContentContainer>
            </Container>
        )
    }
}

export default IndexPageSlideshowSection;