import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import Photo from "../../IndexPage/IndexPageOrderingSection/IndexPhoto2.png";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";
import PhoneIcon from "./PhoneIcon.svg";
import Wave from "../../Assets/Wave/Wave";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  padding-left: 52px;
  float: left;

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 8px;
  left: 0;
  width: 32px;
  height: 32px;
`;

const Title = styled.p`
  color: #222222;
  font-size: 18px;
  margin: 3px 0;
  margin-top: 0;

  @media (max-width: 700px) {
    font-size: 13px;
  }

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const ContentText = styled.p`
  color: #222222;
  font-size: 24px;
  margin: 3px 0;
  font-weight: 600;

  @media (max-width: 700px) {
    font-size: 18px;
    text-decoration: underline;
  }

  @media (min-width: 701px) and (max-width: 1100px) {
    font-size: 22px;
    text-decoration: underline;
  }
`;

const PhoneContainerDesktop = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const PhoneContainerMobile = styled.div`
  @media (min-width: 1101px) {
    display: none;
  }
`

const CallLabel = props => {
    return (
        <Container>
            <Icon src={PhoneIcon} />
            <Title>Zadzwoń i zamów</Title>
            <PhoneContainerDesktop>
                <ContentText>(+48) 789 098 777</ContentText>
            </PhoneContainerDesktop>
            <PhoneContainerMobile>
                <a href="tel:+48-789-098-777"><ContentText>(+48) 789 098 777</ContentText></a>
            </PhoneContainerMobile>
        </Container>
    )
}

export default CallLabel;