import React, {Component} from "react";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import styled from "styled-components";
import Title from "../../Assets/Title/Title";
import {Navigate} from "react-router-dom";
import config from "../../../config.json";
import GetToken from "../../../logic/GetToken/GetToken";
import {AdminPanelTable, AdminPanelTd, AdminPanelTh, AdminPanelTr} from "../AdminPanelTable/AdminPanelTable";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: left;
`;

class AdminLogoutSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: 0,
            isActive: true,
            isLoggedOut: false
        };

        const interval = setInterval(() => {
            if (!this.state.isActive) {
                clearInterval(interval);
            } else {
                this.setState({timer: this.state.timer + 1});

                if (this.state.timer >= this.secondsToLogout) {
                    this.logout();
                    clearInterval(interval);
                }
            }
        }, 1000);
    }

    logout() {
        const token = GetToken();
        fetch(`${config.backendServer}/api/v1/logout`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({token: token})
        })
        .then(result => result.json())
        .then(result => {
                if (result.status === "success") {
                    //localStorage.setItem("token", result.token);
                    this.setState({isLoggedOut: true});
                } else {
                    alert("Wystąpił błąd przy wylogowaniu! Użytkownik nie został wylogowany.");
                }
        })
    }

    secondsToLogout = 3;

    render() {
        return(
            <Container>
                {this.state.isLoggedOut && <Navigate to='/admin/login' replace={true} />}
                <div>


                {
                    this.state.isActive
                    ?
                        <>
                            <div>
                                <AdminPanelTable>
                                    <AdminPanelTr>
                                        <AdminPanelTh>
                                            <>Wylogowanie</>
                                        </AdminPanelTh>
                                    </AdminPanelTr>
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            <p>Zostaniesz wylogowany za {this.secondsToLogout - this.state.timer}s.</p>
                                            <p>Kliknij <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.setState({isActive: false})}>tutaj</span>, aby anulować.</p>
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                </AdminPanelTable>
                            </div>
                        </>
                    :
                    <>
                        <p>Wylogowanie zostało anulowane</p>
                    </>
                }
                </div>
            </Container>
        )
    }
}

export default AdminLogoutSection;