import {useState} from "react";
import styled from "styled-components";

import Photo1 from "./Photo1.png";
import Photo2 from "./Photo2.png";
import Photo3 from "./Photo3.png";
import Photo4 from "./Photo4.png";
import ContentContainer from "../Base/ContentContainer/ContentContainer";

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 80px;
`

const GalleryPhoto = styled.img`
  width: 25%;
  height: 100%;
  object-fit: cover;
  float: left;
`;

const FooterGallery = props => {
    return (
        <Container>
            <> {/* ? */}
                <GalleryPhoto src={Photo1} />
                <GalleryPhoto src={Photo2} />
                <GalleryPhoto src={Photo3} />
                <GalleryPhoto src={Photo4} />
            </>
        </Container>
    )
}

export default FooterGallery;