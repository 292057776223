import React, {Component} from "react";
import styled from "styled-components";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import Title from "../../Assets/Title/Title";
import Clearfix from "../../Base/Clearfix/Clearfix";

import Aos from 'aos';
import "aos/dist/aos.css"
import config from "../../../config.json";
import DrinkIcon from "../../Assets/DrinkIcon/DrinkIcon";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  //width: 573px;
  width: 100%;
  height: auto;
  background-color: #146E55;
  padding: 12px 24px;
  margin: 27px 0;
  border-radius: 10px;
  float: left;
`;

const HorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.separatorColor};
  float: left;
`;

const ProductContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 14px 0;
  padding-right: 50px;
  padding-left: ${props => props.withIcons ? "50px" : "0px"};
  position: relative;
  float: left;
`;

const ProductTextContainer = styled.div`
  width: 100%;
  height: 100%;
  ${props => props.withIcons ? "margin-left: 0px" : "margin-left: 14px"};
  ${props => props.withIcons ? "padding-right: 6px" : "padding-right: 16px"};
  
  float: left;
`;

const ProductName = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 2px 0;
  text-transform: uppercase;
`;

const ProductDescription = styled.p`
  font-size: 14px;
  margin: 1px 0;
`;

const ProductPrice = styled.div`
  position: absolute;
  top: 14px;
  right: 0;
  width: 46px;
  height: calc(100% - 2*14px);
  border-left: 1px solid ${props => props.separatorColor};
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
`;

const ProductIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  float: left;
`;

const ProductIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  ${props => props.isGrayscale ? "filter: brightness(12%) sepia(0%);" : ""}
  float: left;
`;

class MenuSlate extends Component {
    constructor(props) {
        super(props);
        this.state = {styles: [
                {backgroundColor: "#146E55", color: "white", separatorColor: "#03473A", borderColor: "#146E55", titleColor: "white"},
                {backgroundColor: "#434343", color: "white", separatorColor: "#222222", borderColor: "#434343", titleColor: "white"},
                {backgroundColor: "#F0F0F0", color: "black", separatorColor: "#C4C4C4", borderColor: "#146E55", titleColor: "#146E55"}
            ],
            currentStyle: (props.styleIndex ? props.styleIndex : 0)
        };

        Aos.init({ duration: 1000 });
    }

    render() {
        return <Container data-aos="fade-up" style={{backgroundColor: this.state.styles[this.state.currentStyle].backgroundColor, border: `1px solid ${this.state.styles[this.state.currentStyle].borderColor}`, color: this.state.styles[this.state.currentStyle].color}}>
            <SectionTitle style={{color: this.state.styles[this.state.currentStyle].titleColor, fontSize: 20}}>{this.props.title}</SectionTitle>
            {this.props.products.map(product =>
                <>
                <HorizontalSeparator style={{backgroundColor: this.state.styles[this.state.currentStyle].separatorColor}} />
                    <Clearfix />
                <ProductContainer withIcons={this.props.withIcons}>
                    {
                        this.props.withIcons
                        // ? <ProductIcon src={config.backendServer + "/public/product_files/" + product.icon} />
                        ? <ProductIconContainer isGrayscale={this.state.currentStyle === 1} ><DrinkIcon iconIndex={product.icon} /></ProductIconContainer>
                        : ""
                    }
                    <ProductTextContainer withIcons={this.props.withIcons}>
                        <ProductName>{product.name}</ProductName>
                        <ProductDescription>{product.description}</ProductDescription>
                    </ProductTextContainer>

                    <ProductPrice separatorColor={this.state.styles[this.state.currentStyle].separatorColor}>
                        {product.price}
                    </ProductPrice>
                </ProductContainer>
                </>
            )}
            <Clearfix />
        </Container>
    }
}

export default MenuSlate;