import styled from "styled-components";

const InputText = styled.input`
  font-style: normal;
  font-weight: normal;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #FBFBFB;
  border-radius: 8px;
  border: 1px solid #C4C4C4;

  margin: 10px 10px;
`;

export default InputText;