import styled from "styled-components";

const ButtonNormal = styled.button`
  font-style: normal;
  font-weight: normal;
  padding: 12px 28px;
  font-size: 18px;
  color: white;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #146E55;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  margin: 0px 10px;
  
  &:hover {
    background-color: #075C48;
  }
  
  @media (max-width: 1100px) {
    ${props => props.mobileFullWidth ? "width: 100%;" : ""}
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

export default ButtonNormal;