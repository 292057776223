import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import IndexView from "./views/IndexView/IndexView";
import MenuView from "./views/MenuView/MenuVIew";
import ContactView from "./views/ContactView/ContactView";
import AdminLoginView from "./views/Admin/AdminLoginView/AdminLoginView";
import AdminPanelView from "./views/Admin/AdminPanelView/AdminPanelView";

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexView />} />
          <Route path="/menu" element={<MenuView />} />
          <Route path="/kontakt" element={<ContactView />} />


          <Route path="/admin/login" element={<AdminLoginView />} />
          <Route path="/admin" element={<AdminPanelView />} />
        </Routes>
      </BrowserRouter>
  );
}

