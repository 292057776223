import LocationIcon from "../../InfoLabels/AddressLabel/LocationIcon.png";
import styled from "styled-components";
import CallLabel from "../../InfoLabels/CallLabel/CallLabel";
import Clearfix from "../../Base/Clearfix/Clearfix";
import AddressLabel from "../../InfoLabels/AddressLabel/AddressLabel";
import TimeLabel from "../../InfoLabels/TimeLabel/TimeLabel";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";

import Aos from 'aos';
import "aos/dist/aos.css"
import Wave from "../../Assets/Wave/Wave";
import React from "react";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  height: auto;
  float: left;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const LeftSection = styled.div`
  //width: calc(100% - 600px);
  width: 50%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  float: left;
`;

const RightSection = styled.div`
  width: 50%;
  height: auto;
  min-height: 500px;
  float: right;
`

const ContactPageContent = props => {
    return (
        <Container>
            <LeftSection>
                <div>
                    <SectionTitle style={{marginBottom: 28, marginTop: 0}}>ZAMÓWIENIA</SectionTitle>
                    <CallLabel />
                    <Clearfix />
                    <SectionTitle style={{marginBottom: 28}}>WPADNIJ DO NAS!</SectionTitle>
                    <AddressLabel />
                    <TimeLabel style={{marginBottom: 0}} />
                </div>
            </LeftSection>
            <RightSection>
                <iframe
                    data-aos="fade-up"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2322.2000013997163!2d18.57576591602098!3d54.40644420247715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd757616b5425d%3A0x395a01d81df496b8!2sPalec%20do%20budki!5e0!3m2!1spl!2spl!4v1651774985267!5m2!1spl!2spl"
                    width="600" height="500" style={{border:0, borderRadius: "10px", width: "100%"}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </RightSection>
        </Container>
    )
}

export default ContactPageContent;