import styled from "styled-components";

const ButtonOutline = styled.button`
  font-style: normal;
  font-weight: 500;
  padding: 12px 28px;
  font-size: 18px;
  color: #146E55;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #146E55;
  cursor: pointer;

  margin: 0px 10px;
  
  &:hover {
    //border: 2px solid #146E55;
    outline: 1px solid #146E55;
    filter: drop-shadow(0px 2px 9px rgba(3, 71, 58, 0.25));
  }

  @media (max-width: 1100px) {
    ${props => props.mobileFullWidth ? "width: 100%;" : ""}
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

export default ButtonOutline;