import styled from "styled-components";

const AdminPanelTable = styled.table`
  width: 100%;
  height: auto;
  padding: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
  
  overflow: hidden;
  position: relative;
  float: left;
`;

const AdminPanelTr = styled.tr`
  margin: 10px;
  padding: 10px;
  position: relative;
`;

const AdminPanelTh = styled.th`
  background-color: #146E55;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin: 0;
  border: 0;
  position: relative;
  padding: 12px 45px;

  ${props => props.responsive
          ?
          `
  @media (max-width: 1700px) {
    padding: 12px 20px;
  }

  @media (max-width: 1300px) {
    padding: 12px 12px;
  }
  `
          : ""}


`;

const AdminPanelTd = styled.td`
  background-color: #FBFBFB;
  position: relative;
  padding: 45px;

  ${props => props.responsive
  ?
  `
  @media (max-width: 1700px) {
    padding: 20px;
  }

  @media (max-width: 1300px) {
    padding: 12px;
  }
  `
  : ""}
`;

const AdminPanelSeparator = styled.div`
  height: calc(100% - 40px);
  width: 1px;
  background-color: #CDCDCD;
  position: absolute;
  top: 20px;
  right: 0;
  float: right;
`;

export { AdminPanelTable, AdminPanelTr, AdminPanelTh, AdminPanelTd, AdminPanelSeparator };