import React, {Component} from "react";
import styled from "styled-components";
import ButtonOutline from "../../Assets/ButtonOutline/ButtonOutline";
import ButtonNormal from "../../Assets/ButtonNormal/ButtonNormal";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  float: left;
`;

const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 18px;

  @media (max-width: 1100px) {
    height: 0;
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    display: none;
  }
`

const MobileContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  @media (min-width: 1101px) {
    display: none;
  }
`;

class MenuPageNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelectedIndex: 0,
            options: ["PIZZE ROSSE", "PIZZE BIANCHE", "PIZZE SPECIALI", "STARTERY", "DESERY", "NAPOJE"]
        }
    }

    selectOption(optionIndex) {
        this.setState({
            optionSelectedIndex: optionIndex
        })
        this.props.parent.setState({sectionIndex: optionIndex})
    }

    render() {
        return (
            <Container>
                <DesktopContainer>
                    <>
                    {
                        this.state.options.map((option, index) => (
                            <>
                                {this.state.optionSelectedIndex === index
                                    ? <ButtonNormal mobileFullWidth style={{marginTop: 9, marginBottom: 9}}>{option}</ButtonNormal>
                                    : <ButtonOutline mobileFullWidth style={{marginTop: 9, marginBottom: 9}} onClick={() => this.selectOption(index)}>{option}</ButtonOutline>
                                }
                                {
                                    (index + 1)%3 === 0 && index > 0
                                    ? <FlexBreak />
                                    : ""
                                }
                            </>
                        ))
                    }
                    </>
                </DesktopContainer>

                <MobileContainer>
                    <DefaultPaddingBox>
                        {
                            this.state.options.map((option, index) => (
                                <>
                                    {this.state.optionSelectedIndex === index
                                        ? <ButtonNormal mobileFullWidth style={{marginTop: 9, marginBottom: 9, marginLeft: 0, marginRight: 0}}>{option}</ButtonNormal>
                                        : <ButtonOutline mobileFullWidth style={{marginTop: 9, marginBottom: 9, marginLeft: 0, marginRight: 0}} onClick={() => this.selectOption(index)}>{option}</ButtonOutline>
                                    }
                                    {
                                        (index + 1)%3 === 0 && index > 0
                                            ? <FlexBreak />
                                            : ""
                                    }
                                </>
                            ))
                        }
                    </DefaultPaddingBox>
                </MobileContainer>

                {/*<ButtonOutline>PIZZE ROSSE</ButtonOutline>*/}
                {/*<ButtonOutline>PIZZE BIANCHE</ButtonOutline>*/}
                {/*<ButtonOutline>PIZZE SPECIALI</ButtonOutline>*/}
                {/*<FlexBreak />*/}
                {/*<ButtonOutline>STARTERY</ButtonOutline>*/}
                {/*<ButtonOutline>DESERY</ButtonOutline>*/}
                {/*<ButtonNormal>NAPOJE</ButtonNormal>*/}
            </Container>
        )
    }
}

export default MenuPageNavigation;