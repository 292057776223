import React, {Component} from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminLogoutSection from "../AdminLogoutSection/AdminLogoutSection";
import AdminChangePasswordSection from "../AdminChangePasswordSection/AdminChangePasswordSection";
import AdminManageProductsSection from "../AdminManageProductsSection/AdminManageProductsSection";
import AdminManagerProductsSection from "../AdminManageProductsSection/AdminManageProductsSection";
import AdminAddProductSection from "../AdminAddProductSection/AdminAddProductSection";

const Container = styled.div`
  width: 100%;
  height: 100%;
  //background-color: red;
  //padding: 0 20px;
  float: left;
`;

const AdminSectionContentContainer = styled.div`
  width: calc(100% - 330px);
  height: 100%;
  padding: 100px 0;
  margin-left: 330px;
  overflow-x: auto;
  
  float: left;
`;

class AdminPanelPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionIndex: 1,
        }
    }

    setSection = (sectionIndex) => {
        if (sectionIndex === this.state.sectionIndex) {
            this.setState({sectionIndex: -1}, () => {
                this.setState({sectionIndex: sectionIndex});
            });
        } else {
            this.setState({sectionIndex: sectionIndex});
        }
    }

    setEditingProductId = (editingProductId) => {
        this.sections.addProduct = <AdminAddProductSection editingProductId={editingProductId} setSection={this.setSection} />
    }
    clearEditingProductId = () => {
        this.sections.addProduct = <AdminAddProductSection  setSection={this.setSection} />
    }

    sections = {
        "addProduct": <AdminAddProductSection setSection={this.setSection} />,
        "manageProduct": <AdminManageProductsSection setEditingProductId={this.setEditingProductId} setSection={this.setSection} />,
        "changePassword": <AdminChangePasswordSection />,
        "logout": <AdminLogoutSection />
    }

    render() {
        return(
            <Container>
                <AdminSidebar setSection={this.setSection} sectionSelectedIndex={this.state.sectionIndex} clearEditingProductId={this.clearEditingProductId} />
                <AdminSectionContentContainer>
                    {this.state.sectionIndex === 0 ? this.sections["addProduct"] : ""}
                    {this.state.sectionIndex === 1 ? this.sections["manageProduct"] : ""}
                    {this.state.sectionIndex === 2 ? this.sections["changePassword"] : ""}
                    {this.state.sectionIndex === 3 ? this.sections["logout"] : ""}
                </AdminSectionContentContainer>
            </Container>
        )
    }
}

export default AdminPanelPage;