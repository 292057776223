import React, {Component} from "react";
import styled from "styled-components";
import Clearfix from "../../../components/Base/Clearfix/Clearfix";
import ButtonNormal from "../../../components/Assets/ButtonNormal/ButtonNormal";
import IsAdminCheck from "../../../logic/IsAdminCheck/IsAdminCheck";
import config from "../../../config.json";
import { Navigate } from "react-router-dom"

import LogoImg from "./Logo.png";
import InputText from "../../../components/Assets/InputText/InputText";
import CTA from "../../../components/Assets/CTA/CTA";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
`;

const Logo = styled.img`
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
`

const LoginContainer = styled.div`
  text-align: center;
`;

class AdminLoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
            loginInput: "",
            passwordInput: ""
        };

        IsAdminCheck.check().then(isAdmin => {
            console.log("isAdmin: " + isAdmin)
            if (isAdmin) {
                this.setState({isLogged: true});
            }
        })
    }

    login() {
        const loginInput = this.state.loginInput;
        const passwordInput = this.state.passwordInput;
        fetch(`${config.backendServer}/api/v1/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({login: loginInput, password: passwordInput})
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    localStorage.setItem("token", result.token);
                    this.setState({isLogged: true})
                } else {
                    alert("Dane się nie zgadzają, spróbuj ponownie!");
                    window.location.reload();
                }
            })
    }

    handleInputChange(event, inputName) {
        if (inputName === "login") {
            this.setState({loginInput: event.target.value});
        } else if (inputName === "password") {
            this.setState({passwordInput: event.target.value});
        }
    }

    render() {
        return <Container>
            {this.state.isLogged && <Navigate to='/admin' replace={true} />}
            <Logo src={LogoImg} />
            <LoginContainer>
                <h2 style={{fontSize: 24, fontWeight: 600}}>WITAJ W BUDCE!</h2>
                <InputText type="text" placeholder="Nazwa użytkownika" onChange={(e) => this.handleInputChange(e, "login")} />
                <Clearfix />
                <InputText type="password" placeholder="Hasło" onChange={(e) => this.handleInputChange(e, "password")} />
                <Clearfix />
                <CTA style={{marginTop: 20, marginLeft: "50%", transform: "translateX(-50%)", minWidth: "220px"}} onClick={() => this.login()}>ZALOGUJ SIE</CTA>
            </LoginContainer>
        </Container>
    }
}

export default AdminLoginView;