import styled from "styled-components";
import React from "react";
import config from "../../../config.json";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${config.pageHorizontalMargin}px;
  float: left;

  @media (max-width: 1100px) {
    padding: 0 50px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }
`

const DefaultPaddingBox = props => {
    return (
        <Container style={props.style}>
            {props.children}
        </Container>
    )
}

export default DefaultPaddingBox;