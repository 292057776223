import React, {Component} from "react";
import MenuPageNavigation from "./MenuPageNavigation/MenuPageNavigation";
import ContentContainer from "../Base/ContentContainer/ContentContainer";
import Wave from "../Assets/Wave/Wave";
import Clearfix from "../Base/Clearfix/Clearfix";
import MenuPageDrinksSection from "./MenuPageDrinksSection/MenuPageDrinksSection";
import MenuPagePizzasSection from "./MenuPagePizzasSection/MenuPagePizzasSection";
import config from "../../config.json";
import GetToken from "../../logic/GetToken/GetToken";
import LegendIcon from "../Assets/LegendIcon/LegendIcon";
import styled from "styled-components";

const LegendContainer = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  line-height: 25px;
  font-size: 14px;
  float: left;

  @media (max-width: 1100px) {
    margin-top: 20px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
    flex-wrap: wrap;
  }
`;

const LegendItem = styled.div`
  @media (max-width: 1100px) {
    height: auto;
    width: auto;
    margin: 8px 10px;
    float: left;
  }
`;


class MenuPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionIndex: 0,
            products: []
        }
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts() {
        fetch(`${config.backendServer}/api/v1/products`, {
            method: "GET"
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    this.setState({products: result.products})
                }
            })
    }

    getProductsByCategoryId(categoryId) {
        let products = [];
        for (let i = 0; i < this.state.products.length; i++) {
            if (this.state.products[i].categoryId === categoryId) {
                products.push(this.state.products[i])
            }
        }

        return products;
    }

    render() {
        return(
            <>
                <MenuPageNavigation parent={this} />

                <LegendContainer>
                    <LegendItem>
                        <LegendIcon type="spicy" style={{marginRight: 8}} />Pizze pikantne
                    </LegendItem>
                    <LegendItem>
                        <LegendIcon type="vege" style={{marginRight: 10}} />Pizze vege
                    </LegendItem>
                    <LegendItem>
                        <LegendIcon type="pizzaOfTheMonth" style={{marginRight: 10}} />Zapytaj obsługę o pizzę miesiąca
                    </LegendItem>
                </LegendContainer>


                <>
                    <Wave width="780px" style={{marginTop: 36, marginBottom: 30}} />
                </>
                <Clearfix />

                {
                    this.state.sectionIndex < 5
                        ? <MenuPagePizzasSection products={this.getProductsByCategoryId(this.state.sectionIndex)} />
                        : ""
                }

                {
                    this.state.sectionIndex === 5
                    ? <MenuPageDrinksSection products={this.getProductsByCategoryId(this.state.sectionIndex)} />
                    : ""
                }


                <Clearfix />

                <>
                    <Wave width="1260px" style={{marginTop: 70, marginBottom: 70}} />
                </>
            </>
        )
    }
}

export default MenuPage;