import React, {Component} from "react";
import Header from "../../components/Header/Header";
import Clearfix from "../../components/Base/Clearfix/Clearfix";
import IndexPageWelcomeSection from "../../components/IndexPage/IndexPageWelcomeSection/IndexPageWelcomeSection";
import IndexPageHistorySection from "../../components/IndexPage/IndexPageHistorySection/IndexPageHistorySection";
import IndexPageSlideshowSection from "../../components/IndexPage/IndexPageSlideshowSection/IndexPageSlideshowSection";
import IndexPageOrderingSection from "../../components/IndexPage/IndexPageOrderingSection/IndexPageOrderingSection";
import IndexPageAddressSection from "../../components/IndexPage/IndexPageAddressSection/IndexPageAddressSection";
import FooterGallery from "../../components/FooterGallery/FooterGallery";
import Footer from "../../components/Footer/Footer";
import Wave from "../../components/Assets/Wave/Wave";
import MenuPageDrinksSection from "../../components/MenuPage/MenuPageDrinksSection/MenuPageDrinksSection";
import ContentContainer from "../../components/Base/ContentContainer/ContentContainer";
import MenuPageNavigation from "../../components/MenuPage/MenuPageNavigation/MenuPageNavigation";
import MenuPage from "../../components/MenuPage/MenuPage";
import ScrollToTopButton from "../../components/Assets/ScrollToTopButton/ScrollToTopButton";
import BodyContainer from "../../components/Base/BodyContainer/BodyContainer";

class MenuView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <BodyContainer>
            <Header indexSelected={1} />
            <Clearfix />
            <ScrollToTopButton />

            <MenuPage />

            <FooterGallery />
            <Clearfix />
            <Footer />
        </BodyContainer>
    }
}

export default MenuView;