import styled from "styled-components";
import LogoImg from "./Logo.png";

import AddProductIcon from "./AddProductIcon.png";
import ManageProductsIcon from "./ManageProductsIcon.png";
import ChangePasswordIcon from "./ChangePasswordIcon.png";
import LogoutIcon from "./LogoutIcon.png";
import {Link} from "react-router-dom";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 330px;
  height: 100%;
  background-color: #146E55;
`

const NavigationContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: plum;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);  
`;

const NavigationButton = styled.button`
  width: 100%;
  height: 65px;
  padding: 25px 45px;
  border: none;
  color: white;
  background-color: ${props => props.isSelected ? "#03473A" : "#146E55"};
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  text-align: left;
  padding-left: calc(50px + 25px);
  float: left;
  
  &:hover {
    background-color: #075C48;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 40px;
  left: 36px;
  width: calc(100% - 72px);
  height: auto;
  cursor: pointer;
`;

const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 40px;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
`;

const AdminSidebar = props => {

  return(
        <Container>
            <Link to="/">
                <Logo src={LogoImg} />
            </Link>
            <NavigationContainer>
              <NavigationButton onClick={() => {props.clearEditingProductId(); props.setSection(0)}} isSelected={props.sectionSelectedIndex === 0}>
                  <Icon src={AddProductIcon} />
                  Dodaj produkt
              </NavigationButton>
              <NavigationButton onClick={() => props.setSection(1)} isSelected={props.sectionSelectedIndex === 1}>
                  <Icon src={ManageProductsIcon} />
                  Zarządzaj produktami
              </NavigationButton>
              <NavigationButton onClick={() => props.setSection(2)} isSelected={props.sectionSelectedIndex === 2}>
                  <Icon src={ChangePasswordIcon} />
                  Zmień hasło
              </NavigationButton>
              <NavigationButton onClick={() => props.setSection(3)} isSelected={props.sectionSelectedIndex === 3}>
                  <Icon src={LogoutIcon} />
                  Wyloguj się
              </NavigationButton>
            </NavigationContainer>
        </Container>
  );
}

export default AdminSidebar;