import LocationIcon from "../../InfoLabels/AddressLabel/LocationIcon.png";
import styled from "styled-components";
import CallLabel from "../../InfoLabels/CallLabel/CallLabel";
import Clearfix from "../../Base/Clearfix/Clearfix";
import AddressLabel from "../../InfoLabels/AddressLabel/AddressLabel";
import TimeLabel from "../../InfoLabels/TimeLabel/TimeLabel";
import ContentContainer from "../../Base/ContentContainer/ContentContainer";

import Aos from 'aos';
import "aos/dist/aos.css"
import Wave from "../../Assets/Wave/Wave";
import React from "react";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";

const Container = styled.div`
  width: 100%;
  //height: 400px;
  min-height: 100vh;
  //margin-top: -4px;
  margin-bottom: -80px;
  position: relative;

  float: left;

  @media (min-width: 1101px) {
    display: none;
  }
`

const MapContainer = styled.div`
  width: calc(100% - 40px);
  height: calc(80vh - 180px);
  margin: 0 20px;
  margin-top: 5vh;
  margin-bottom: calc(5vh + 24px);
  float: left;

  @media (min-width: 1101px) {
    display: none;
  }

  width: calc(100%);
  margin-right: 0;
  margin-left: 0;
`

const ContactPageContent = props => {
    return (
        <Container>
            <>
                <DefaultPaddingBox>
                    <SectionTitle style={{marginBottom: 28, marginTop: 0}}>ZAMÓWIENIA</SectionTitle>
                    <CallLabel />
                    <Clearfix />
                    <SectionTitle>WPADNIJ DO NAS</SectionTitle>
                    <AddressLabel />
                    <TimeLabel />
                </DefaultPaddingBox>
                <DefaultPaddingBox>
                    <MapContainer>
                        <iframe
                            data-aos="fade-left"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2322.2000013997163!2d18.57576591602098!3d54.40644420247715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd757616b5425d%3A0x395a01d81df496b8!2sPalec%20do%20budki!5e0!3m2!1spl!2spl!4v1651774985267!5m2!1spl!2spl"
                            width="300" height="380" style={{border:0, borderRadius: "0px", float: "left", width: "100%", height: "100%"}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </MapContainer>
                </DefaultPaddingBox>
            </>
        </Container>
    )
}

export default ContactPageContent;