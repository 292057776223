import ContentContainer from "../../Base/ContentContainer/ContentContainer";
import GoogleMapReact from 'google-map-react';
import DefaultPaddingBox from "../../Base/DefaultPaddingBox/DefaultPaddingBox";
import Title from "../../Assets/Title/Title";
import styled from "styled-components";
import TimeLabel from "../../InfoLabels/TimeLabel/TimeLabel";
import AddressLabel from "../../InfoLabels/AddressLabel/AddressLabel";

import Aos from 'aos';
import "aos/dist/aos.css"
import {useEffect} from "react";
import SectionTitle from "../../Assets/SectionTitle/SectionTitle";

const Container = styled.div`
  width: 100%;
  //height: 400px;
  height: 50vh;
  //margin-top: -4px;
  margin-bottom: -80px;
  position: relative;
  
  float: left;

  @media (max-width: 1100px) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 31;
`;

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  
  float: left;
`;

const LeftSectionElement = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  padding-left: 52px;
  float: left;
`;

const LeftSectionElementIcon = styled.img`
  position: absolute;
  top: 8px;
  left: 0;
  width: 32px;
`;

const LeftSectionElementTitle = styled.p`
  color: #222222;
  font-size: 18px;
  margin: 3px 0;
  margin-top: 0;
`;

const LeftSectionElementContentText = styled.p`
  color: #222222;
  font-size: 24px;
  margin: 3px 0;
`;

const RightSection = styled.div`
  width: calc(50% + 120px);
  height: 50vh;
  margin-right: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
`;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const IndexPageAddressSection = props => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    },[])
    return (
        <Container>
            <Wrapper>
                <DefaultPaddingBox>
                    <LeftSection data-aos="fade-up">
                        <div>
                            <SectionTitle>WPADNIJ DO NAS</SectionTitle>
                            <AddressLabel />
                            <TimeLabel />
                        </div>
                    </LeftSection>
                    <RightSection>
                        <iframe
                            data-aos="fade-left"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2322.2000013997163!2d18.57576591602098!3d54.40644420247715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd757616b5425d%3A0x395a01d81df496b8!2sPalec%20do%20budki!5e0!3m2!1spl!2spl!4v1651774985267!5m2!1spl!2spl"
                            width="600" height="380" style={{border:0, borderRadius: "0px", float: "left", width: "100%", height: "100%"}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </RightSection>
                </DefaultPaddingBox>
            </Wrapper>
        </Container>
    )
}

export default IndexPageAddressSection;