import React, {Component, useState} from "react";
import styled from "styled-components";
import config from "../../../config.json";
import HeaderLogoImage from "../../Header/HeaderLogo.png";

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const SubContainer = styled.div`
  width: calc(100% - 0px);
  max-width: calc(1600px);
  margin-bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 100%;
  color: black;
  position: relative;
  float: left;
`;

const ContentContainer = props => {
    return (
        <Container>
            <SubContainer>
                {props.children}
            </SubContainer>
        </Container>
    )
}

export default ContentContainer;