import styled from "styled-components";

const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #146E55;

  @media (max-width: 1100px) {
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

export default SectionTitle;