import styled from "styled-components";

// import Icon0 from "../../../res/icons/icon0.png";
// import Icon1 from "../../../res/icons/icon1.png";
// import Icon2 from "../../../res/icons/icon2.png";
// import Icon3 from "../../../res/icons/icon3.png";
// import Icon4 from "../../../res/icons/icon4.png";
// import Icon5 from "../../../res/icons/icon5.png";
// import Icon6 from "../../../res/icons/icon6.png";
// import Icon7 from "../../../res/icons/icon7.png";
// import Icon8 from "../../../res/icons/icon8.png";

import Icon0 from "../../../res/icons/icon0.svg";
import Icon1 from "../../../res/icons/icon1.svg";
import Icon2 from "../../../res/icons/icon2.svg";
import Icon3 from "../../../res/icons/icon3.svg";
import Icon4 from "../../../res/icons/icon4.svg";
import Icon5 from "../../../res/icons/icon5.svg";
import Icon6 from "../../../res/icons/icon6.svg";
import Icon7 from "../../../res/icons/icon7.svg";
import Icon8 from "../../../res/icons/icon8.svg";

const Container = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 3px;
  float: left;
`;

const Icon = styled.img`
  height: 100%;
  width: auto;
  float: left;
  
  ${props => props.grayscale ? "filter: invert(8%) sepia(28%) saturate(14%) hue-rotate(341deg) brightness(108%) contrast(90%);" : ""}
`;

const DrinkIcon = props => {
    const icons = [Icon0, Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8];
    let src = icons[props.iconIndex];
    return(
        <Container style={props.style} onClick={props.onClick}>
            <Icon src={src} grayscale={props.grayscale} />
        </Container>
    );
}

export default DrinkIcon;