import styled from "styled-components";
import PizzaPhoto from "./PizzaPhoto.png";
import Clearfix from "../../../Base/Clearfix/Clearfix";
import {Component} from "react";
import React from "react";
import Aos from 'aos';
import "aos/dist/aos.css"
import config from "../../../../config.json";
import LegendIcon from "../../../Assets/LegendIcon/LegendIcon";

const PizzaImg = styled.img`
  position: absolute;
  top: 0;
  left: 16px;
  width: calc(100% - 32px);
  transition: 0.45s;

  transform: rotate(${props => props.rotation}deg);
  
  z-index: -1;
`;


const Container = styled.div`
  width: calc((1/3)*100% - 36px);
  height: 1px;
  min-height: 376px;
  height: 100%;
  //background-color: #61dafb;
  margin: 45px ${props => props.horizontalMargins ? "54" : "0"}px;
  position: relative;
  //overflow: revert;
  overflow: visible;
  //background-color: aqua;
  &:hover ${PizzaImg} {
    transform: rotate(180deg);
  }

  float: left;
  overflow: hidden;

  @media (max-width: 1100px) {
    width: calc((1/2)*100% - 1 * 54px);
    margin: 45px 27px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 600px;
    margin: 28px 0;
  }
`;



const InformationBox = styled.div`
  //position: absolute;
  //top: 50%;
  //left: 0;
  margin-top: 50%;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  float: left;
  position: relative;
`;

const PizzaName = styled.div`
  width: auto;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 8px;
  float: left;
`;

const PizzaDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 32px;
`;

const PizzaPrice = styled.p`
  position: absolute;
  bottom: 16px;
  left: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;


class PizzaElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotation: 0,
            containerHeight: 0
        }
        console.log(this.props.productData)
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            containerHeight: this.myRef.current.offsetHeight
        })
    }

    render() {
        return (
            <Container ref={this.myRef} data-aos="zoom-in" horizontalMargins={this.props.horizontalMargins} onMouseEnter={() => this.setState({rotation: this.state.rotation + 120})}>
                <PizzaImg draggable="false" src={config.backendServer + "/public/product_files/" + this.props.productData.photo} rotation={0} />
                <Clearfix/>
                <InformationBox style={{minHeight: this.state.containerHeight/2}}>
                    <PizzaName>
                        {this.props.productData.name}
                    </PizzaName>
                    <div style={{float: "left", marginLeft: 10}}>
                        {this.props.productData.isSpicy ? <LegendIcon type="spicy" /> : ""}
                        {this.props.productData.isVege ? <LegendIcon type="vege" /> : ""}
                    </div>
                    <Clearfix />
                    <PizzaDescription>
                        {this.props.productData.description}
                    </PizzaDescription>
                    <PizzaPrice>{this.props.productData.price} zł</PizzaPrice>
                </InformationBox>
            </Container>
        )
    }
}

export default PizzaElement;