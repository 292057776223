import TimeIcon from "./TimeIcon.svg";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  padding-left: 52px;
  float: left;

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 8px;
  left: 0;
  width: 32px;
  height: 32px;
`;

const Title = styled.p`
  color: #222222;
  font-size: 18px;
  margin: 3px 0;
  margin-top: 0;

  @media (max-width: 700px) {
    font-size: 13px;
  }

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const ContentText = styled.p`
  color: #222222;
  font-size: 24px;
  margin: 3px 0;
  font-weight: 600;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (min-width: 701px) and (max-width: 1100px) {
    font-size: 22px;
  }
`;


const TimeLabel = props => {
    return (
        <Container style={props.style}>
            <Icon src={TimeIcon} />
            <Title>Godziny otwarcia</Title>
            <ContentText>Pon - Ndz 12:00 - 22:00</ContentText>
        </Container>
    )
}

export default TimeLabel;