import styled from "styled-components";
import ArrowImg from "./Arrow.png";
import {Component} from "react";

const Container = styled.div`
  width: 100%;
  height: 45px;
  font-style: normal;
  font-weight: normal;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #146E55;
  border-radius: ${props => props.isOpened ? "10px 10px 0 0" : "8px"};
  border: 1px solid #C4C4C4;
  user-select: none;
  position: relative;

  margin: 10px 0;
  color: white;
`;

const List = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0px 16px;
  background-color: #FFFFFF;
  color: #222222;
  border-radius: 0 0 10px 10px;
  border: 1px solid #C4C4C4;
  border-top: none;
  z-index: 10;
`;

const ListItem = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 12px 0;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
  list-style-type: none;
  
  &:hover {
    background-color: #C4C4C4;
  }
`;

const ArrowImage = styled.img`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%) ${props => props.isReversed ? " rotate(180deg)" : ""};
`;

class Select extends Component {
    constructor(props) {
        super(props);

        let defaultSelectionIndex = (this.props.defaultSelectionIndex ? this.props.defaultSelectionIndex : -1);
        if (defaultSelectionIndex === -1 && this.props.defaultSelection) {
            for (let i = 0; i < this.props.categories.length; i++) {
                if (this.props.categories[i] === this.props.defaultSelection) {
                    defaultSelectionIndex = i;
                }
            }
        }

        this.state = {
            isOpened: false,
            categorySelectedIndex: defaultSelectionIndex
        }
    }

    selectCategoryIndex(categoryIndex) {
        this.setState({categorySelectedIndex: categoryIndex});
    }

    getSelectedCategoryIndex() {
        return this.state.categorySelectedIndex;
    }

    handleChange(categorySelectedIndex) {
        this.setState({categorySelectedIndex: categorySelectedIndex});

        if (this.props.onChange) {
            this.props.onChange({index: categorySelectedIndex, name: this.props.categories[categorySelectedIndex]});
        }
    }

    render() {
        return (
            <Container style={this.props.style} isOpened={this.state.isOpened} onClick={() => this.setState({isOpened: !this.state.isOpened})}>
                <ArrowImage src={ArrowImg} isReversed={this.state.isOpened} />
                {this.state.isOpened ? "Kategorie" : (this.state.categorySelectedIndex === -1 ? "Wybierz kategorie" : this.props.categories[this.state.categorySelectedIndex])}
                {this.state.isOpened
                    ?
                        <List>
                            <ul style={{paddingLeft: 0}}>
                                {
                                    this.props.categories.map((category, index) => (
                                        <ListItem onClick={() => this.handleChange(index)}>{category}</ListItem>
                                    ))
                                }
                            </ul>
                        </List>
                    : ""
                }
            </Container>
        )
    }
}

export default Select;