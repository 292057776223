import React, {Component} from "react";
import config from "../../config.json";
import styled from "styled-components";
import Header from "../../components/Header/Header";
import IndexPageHistorySection from "../../components/IndexPage/IndexPageHistorySection/IndexPageHistorySection";
import ContentContainer from "../../components/Base/ContentContainer/ContentContainer";
import IndexPageSlideshowSection from "../../components/IndexPage/IndexPageSlideshowSection/IndexPageSlideshowSection";
import Clearfix from "../../components/Base/Clearfix/Clearfix";
import IndexPageOrderingSection from "../../components/IndexPage/IndexPageOrderingSection/IndexPageOrderingSection";
import IndexPageAddressSection from "../../components/IndexPage/IndexPageAddressSection/IndexPageAddressSection";
import IndexPageWelcomeSection from "../../components/IndexPage/IndexPageWelcomeSection/IndexPageWelcomeSection";
import Footer from "../../components/Footer/Footer";
import FooterGallery from "../../components/FooterGallery/FooterGallery";
import ScrollToTopButton from "../../components/Assets/ScrollToTopButton/ScrollToTopButton";
import BodyContainer from "../../components/Base/BodyContainer/BodyContainer";
import IndexPageHistorySectionMobile
    from "../../components/IndexPage/IndexPageHistorySection/IndexPageHistorySectionMobile";
import IndexPageOrderingSectionMobile
    from "../../components/IndexPage/IndexPageOrderingSection/IndexPageOrderingSectionMobile";
import IndexPageAddressSectionMobile
    from "../../components/IndexPage/IndexPageAddressSection/IndexPageAddressSectionMobile";


class IndexView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <BodyContainer>
            <Header isAbsolute={true} areColorsReversed={true} indexSelected={0} />
            <Clearfix />
            <ScrollToTopButton />
            <IndexPageWelcomeSection />

            <IndexPageHistorySection />
            <IndexPageHistorySectionMobile />
            <IndexPageSlideshowSection />
            <IndexPageOrderingSection />
            <IndexPageOrderingSectionMobile />
            <IndexPageAddressSection />
            <IndexPageAddressSectionMobile />
            <Clearfix />

            <FooterGallery />
            <Clearfix />
            <Footer />
        </BodyContainer>
    }
}

export default IndexView;