import styled from "styled-components";
import WaveImg from '../../../res/waveImg.png';

const Container = styled.div`
  width: ${props => props.width ? props.width : "90%"};
  height: auto;
  overflow: hidden;
`;

const WaveImage = styled.img`
  width: 1440px;
`

const Wave = props => {
    return (
        <Container width={props.width}>
            <WaveImage src={WaveImg} draggable="false" style={props.style} />
        </Container>
    )
}

export default Wave;