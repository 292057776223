import HeaderLogoImage from './HeaderLogo.svg';
import HeaderLogoMobileImage from './HeaderLogoMobile.svg';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import config from "../../config.json";
import ButtonOutline from "../Assets/ButtonOutline/ButtonOutline";
import Aos from 'aos';
import "aos/dist/aos.css"

import Hamburger from 'hamburger-react';
import Clearfix from "../Base/Clearfix/Clearfix";

const Container = styled.div`
  width: 100%;
  height: 180px;

  @media (max-width: 1100px) {
    height: 68px;
  }
  
  ${props => props.isStatic ? "margin-bottom: 60px;"
  : `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  `
  }
  
`

const LogoImage = styled.img`
  width: 112px;
  height: 140px;
  position: absolute;
  top: 40px;
  left: ${config.pageHorizontalMargin}px;
  ${props => props.areColorsReversed ? "filter: brightness(0) invert(1);" : "filter: invert(29%) sepia(10%) saturate(6977%) hue-rotate(128deg) brightness(98%) contrast(84%);"}
`

const LogoMobileImage = styled.img`
  position: absolute;
  //width: 142px;
  top: 26px;
  left: 22px;
  ${props => props.areColorsReversed ? "filter: brightness(0) invert(1);" : ""}

  @media (min-width: 1101px) {
    display: none;
  }

  @media (max-width: 700px) {
    top: 26px;
    left: 22px;
  }

  @media (max-width: 1100px) and (min-width: 701px) {
    top: 26px;
    left: 50px;
    height: 28px;
  }
`

const SubContainer = styled.div`
  width: calc(100% - 0px);
  //max-width: calc(1600px + 0*300px);
  margin-bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 100%;
  padding: 0 120px;
  position: relative;
  float: left;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const DesktopPanel = styled.div`
  width: auto;
  height: auto;
  margin-top: 100px;
  float: right;
`;

const DesktopPanelButton = styled.button`
  width: auto;
  height: auto;
  border: none;
  font-size: 18px;
  padding: 0;
  margin-right: 36px;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.areColorsReversed ? "white" : "black"};
  
  font-weight: ${props => props.isSelected ? "bold" : "normal"};
  text-decoration: ${props => props.isSelected ? "underline" : "unset"};
  float: left;
  
  &:hover {
    //font-weight: 600;
    text-decoration: underline;
  }
`;

const DesktopPanelSpecialButtonContainer = styled.div`
  width: auto;
  height: auto;
  margin-top: -12px;
  margin-left: -6px;
  float: left;
`;

const ButtonOutlineReversed = styled.button`
  font-style: normal;
  font-weight: 500;
  padding: 12px 28px;
  font-size: 18px;
  color: #146E55;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #146E55;
  cursor: pointer;

  margin: 0px 10px;

  color: white;
  border-color: white;
  margin-right: 0;
  
  &:hover {
    //border: 2px solid #146E55;
    outline: 1px solid #F0F0F0;
    filter: drop-shadow(0px 2px 9px rgba(3, 71, 58, 0.25));
  }

  @media (max-width: 1100px) {
    ${props => props.mobileFullWidth ? "width: 100%;" : ""}
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const HamburgerContainer = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
  @media (max-width: 1100px) and (min-width: 701px) {
    right: 39px;
  }
  color: #E5E5E5;
  ${props => (!props.areColorsReversed && !props.isOpened) ? "color: #146E55;" : ""}
  position: absolute;
  top: 14px;
  right: 13px;
  width: 48px;
  height: 48px;
  z-index: 30;
  
  ${props => props.isOpened ? "position: fixed;" : ""}
`

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #146E55;
  text-align: center;
  padding-top: 17px;
  z-index: 29;
  touch-action: none;
  padding-top: 120px;

  @media (min-width: 1100px) {
    display: none;
  } 
`

const MobileButton = styled.button`
  width: auto;
  height: auto;
  margin-bottom: 35px;
  text-align: center;
  color: white;
  font-size: 18px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${props => props.isSelected ? "font-weight: 700;" : ""}
  text-decoration: ${props => props.isSelected ? "underline" : "unset"};
`;

const MobileBottomButtonContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
`;

const TabletArea = styled.div`
  @media (max-width: 700px) or (min-width: 1100px) {
    display: none;
  }
`;

const MobileOnlyArea = styled.div`
  @media (min-width: 701px) {
    display: none;
  }
`;

const Header = props => {
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        Aos.init({ duration: 1350 });
    },[])
    return (
        <Container isStatic={!props.isAbsolute}>
            <HamburgerContainer isOpened={isOpen} areColorsReversed={props.areColorsReversed}>
                <Hamburger size={24} distance="lg" toggled={isOpen} toggle={setOpen} onClick={() => alert(1)} />
            </HamburgerContainer>
            {
                isOpen
                ?
                    <MobileMenuContainer>
                        <Link to="/"><MobileButton isSelected={props.indexSelected === 0}>Strona główna</MobileButton></Link>
                        <Clearfix />
                        <Link to="/menu"><MobileButton isSelected={props.indexSelected === 1}>Menu</MobileButton></Link>
                        <Clearfix />
                        <Link to="/kontakt"><MobileButton isSelected={props.indexSelected === 2}>Kontakt</MobileButton></Link>
                        {props.indexSelected === 2
                            ?
                            <MobileBottomButtonContainer>
                                <Link
                                    to="/menu"><ButtonOutlineReversed>POZNAJ MENU</ButtonOutlineReversed></Link>
                            </MobileBottomButtonContainer>
                            :
                            <MobileBottomButtonContainer>
                                <MobileOnlyArea>
                                    <a href="tel:+48-789-098-777"><ButtonOutlineReversed>ZADZWOŃ I ZAMÓW</ButtonOutlineReversed></a>
                                </MobileOnlyArea>
                                <TabletArea>
                                    <Link to="/kontakt"><ButtonOutlineReversed>ZADZWOŃ I ZAMÓW</ButtonOutlineReversed></Link>
                                </TabletArea>
                            </MobileBottomButtonContainer>
                        }
                    </MobileMenuContainer>
                :
                    ""
            }
            {
                props.indexSelected !== 0
                ?
                    <Link to="/">
                        <LogoMobileImage src={HeaderLogoMobileImage} areColorsReversed={props.areColorsReversed} />
                    </Link>
                :
                    ""
            }
            <SubContainer>
                <Link to="/">
                    <LogoImage src={HeaderLogoImage} areColorsReversed={props.areColorsReversed} />
                </Link>
                <DesktopPanel data-aos="fade-left" data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500">
                    <Link to="/"><DesktopPanelButton areColorsReversed={props.areColorsReversed} isSelected={props.indexSelected === 0}>Strona główna</DesktopPanelButton></Link>
                    <Link to="/menu"><DesktopPanelButton areColorsReversed={props.areColorsReversed} isSelected={props.indexSelected === 1}>Menu</DesktopPanelButton></Link>
                    <Link to="/kontakt"><DesktopPanelButton areColorsReversed={props.areColorsReversed} isSelected={props.indexSelected === 2}>Kontakt</DesktopPanelButton></Link>


                    <DesktopPanelSpecialButtonContainer data-aos="zoom-in">
                        {
                            props.areColorsReversed
                                ? <Link to={props.indexSelected === 2 ? "/menu" : "/kontakt"}><ButtonOutlineReversed>{props.indexSelected === 2 ? "POZNAJ MENU" : "ZADZWOŃ I ZAMÓW"}</ButtonOutlineReversed></Link>
                            : <Link to={props.indexSelected === 2 ? "/menu" : "/kontakt"}><ButtonOutline style={{color: "#146E55", marginRight: 0}}>{props.indexSelected === 2 ? "POZNAJ MENU" : "ZADZWOŃ I ZAMÓW"}</ButtonOutline></Link>
                        }
                    </DesktopPanelSpecialButtonContainer>

                </DesktopPanel>
            </SubContainer>
        </Container>
    )
}

export default Header;