import React, {Component} from "react";
import ButtonNormal from "../../Assets/ButtonNormal/ButtonNormal";
import ButtonOutline from "../../Assets/ButtonOutline/ButtonOutline";
import Clearfix from "../../Base/Clearfix/Clearfix";
import {
    AdminPanelSeparator,
    AdminPanelTable,
    AdminPanelTd,
    AdminPanelTh,
    AdminPanelTr
} from "../AdminPanelTable/AdminPanelTable";
import styled from "styled-components";
import CTA from "../../Assets/CTA/CTA";
import InputText from "../../Assets/InputText/InputText";
import Select from "../../Assets/Select/Select";
import config from "../../../config.json";
import GetToken from "../../../logic/GetToken/GetToken";
import LegendIcon from "../../Assets/LegendIcon/LegendIcon";
import DrinkIcon from "../../Assets/DrinkIcon/DrinkIcon";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
  float: left;
`;

class AdminAddProductSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: (props.editingProductId ? "edit" : "add"),
            isLoaded: (props.editingProductId ? false : true),
            productId: "",
            productCategoryId: 0,
            productTag: "",
            productName: "",
            productDescription: "",
            productPrice: "",
            productIsSpicy: 0,
            productIsVege: 0,
            productIcon: "",
            productPhoto: "",
            productPositionPriority: 0
        };

        if (this.state.mode === "edit") {
            this.loadProductData(this.props.editingProductId);
        }
    }

    loadProductData = productId => {
        fetch(`${config.backendServer}/api/v1/product?token=${GetToken()}&productId=${productId}`, {
            method: "GET"
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    const { product } = result;
                    this.setState({
                        isFeedback: false,
                        feedbackMessage: "",
                        isLoaded: true,
                        productId: product.id,
                        productCategoryId: product.categoryId,
                        productTag: product.tag,
                        productName: product.name,
                        productDescription: product.description,
                        productPrice: product.price,
                        productIsSpicy: product.isSpicy,
                        productIsVege: product.isVege,
                        productIcon: product.icon,
                        productPhoto: product.photo,
                        productPositionPriority: product.positionPriority
                    })
                }
            })
    }

    save() {
        let formData = new FormData();
        if (this.state.mode === "edit") {
            formData.append('productId', this.state.productId);
        }
        formData.append('categoryId', this.state.productCategoryId);
        formData.append('tag', this.state.productTag);
        formData.append('name', this.state.productName);
        formData.append('description', this.state.productDescription);
        formData.append('price', this.state.productPrice);
        formData.append('isSpicy', this.state.productIsSpicy);
        formData.append('isVege', this.state.productIsVege);
        formData.append('positionPriority', this.state.productPositionPriority);
        formData.append("icon", ((this.state.productIcon || this.state.productIcon === 0) ? (["KOKTAJLE KLASYCZNE", "KOKTAJLE BEZ ALKOHOLU", "DOMOWE LEMONIADY", "SPECJAŁY NASZEGO BARU"].includes(this.state.productTag) ? this.state.productIcon.toString() : "") : ""));
        const inputFiles = document.querySelectorAll('input[type="file"]');
        let photoFile = document.getElementById('photo-file');
        if (photoFile && photoFile.files.length === 1) {
            formData.append("photo", photoFile.files[0]);
        }

        fetch(`${config.backendServer}/api/v1/product?token=${GetToken()}`, {
            method: (this.state.mode === "edit" ? "PUT" : "POST"),
            body: formData
            // body: JSON.stringify({
            //     productId: this.state.productId,
            //     name: this.state.productName,
            //     description: this.state.productDescription,
            //     price: this.state.productPrice,
            //     isSpicy: this.state.productIsSpicy,
            //     isVege: this.state.productIsVege,
            //     positionPriority: this.state.productPositionPriority
            // })
        }).then(result => result.json())
            .then(result => {
                if (result.status === "success") {
                    this.setState({
                        isFeedback: true,
                        feedbackMessage: "Sukces"
                    })
                } else {
                    this.setState({
                        isFeedback: true,
                        feedbackMessage: "Wystąpił błąd!"
                    })
                }
            })
    }

    handleInputChange(event, inputName) {
        if (inputName === "productName") {
            this.setState({productName: event.target.value});
        } else if (inputName === "productDescription") {
            this.setState({productDescription: event.target.value});
        } else if (inputName === "productPrice") {
            this.setState({productPrice: event.target.value});
        } else if (inputName === "productPositionPriority") {
            this.setState({productPositionPriority: event.target.value});
        }
    }

    render() {
        return(
            <Container>
                <div>
                    {
                        !this.state.isFeedback
                        ?
                            (
                                <AdminPanelTable>
                                    <AdminPanelTr>
                                        <AdminPanelTh>Uzupełnij informacje o produkcie:</AdminPanelTh>
                                        <AdminPanelTh></AdminPanelTh>
                                    </AdminPanelTr>
                                    {
                                        this.state.mode === "edit"
                                            ?
                                            <AdminPanelTr>
                                                <AdminPanelTd>
                                                    ID
                                                    <AdminPanelSeparator />
                                                </AdminPanelTd>
                                                <AdminPanelTd>
                                                    <InputText type="text" style={{width: "100%", margin: "0 0"}} value={this.props.editingProductId} disabled="disabled" />
                                                </AdminPanelTd>
                                            </AdminPanelTr>
                                            : ""
                                    }
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            Kategoria
                                            <AdminPanelSeparator />
                                        </AdminPanelTd>
                                        <AdminPanelTd>
                                            {
                                                this.state.isLoaded
                                                    ?
                                                    <Select defaultSelectionIndex={this.state.productCategoryId} onChange={(value) => this.setState({productCategoryId: value.index})} categories={["PIZZE ROSSE", "PIZZE BIANCHE", "PIZZE SPECIALI", "STARTERY", "DESERY", "NAPOJE"]}>Wybierz kategorię</Select>
                                                    : ""
                                            }

                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                    {
                                        this.state.productCategoryId === 5
                                            ?
                                            <AdminPanelTr>
                                                <AdminPanelTd>
                                                    Tag
                                                    <AdminPanelSeparator />
                                                </AdminPanelTd>
                                                <AdminPanelTd>
                                                    <Select defaultSelection={this.state.productTag} onChange={(value) => this.setState({productTag: value.name})} categories={["KOKTAJLE KLASYCZNE", "SPECJAŁY NASZEGO BARU", "PIWA", "KOKTAJLE BEZ ALKOHOLU", "DOMOWE LEMONIADY", "NAPOJE ZIMNE", "KAWY", "HERBATY"]}>Wybierz tag</Select>
                                                </AdminPanelTd>
                                            </AdminPanelTr>
                                            : ""
                                    }
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            Nazwa
                                            <AdminPanelSeparator />
                                        </AdminPanelTd>
                                        <AdminPanelTd>
                                            <InputText type="text" style={{width: "100%", margin: "0 0"}} placeholder="Nazwa produktu" value={this.state.productName} onChange={(e) => this.handleInputChange(e, "productName")} />
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            Składniki
                                            <AdminPanelSeparator />
                                        </AdminPanelTd>
                                        <AdminPanelTd>
                                            <InputText type="text" style={{width: "100%", margin: "0 0"}} placeholder="Składniki produktu" value={this.state.productDescription} onChange={(e) => this.handleInputChange(e, "productDescription")} />
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            Cena
                                            <AdminPanelSeparator />
                                        </AdminPanelTd>
                                        <AdminPanelTd>
                                            <InputText type="number" style={{width: "100%", margin: "0 0"}} placeholder="Cena produktu" value={this.state.productPrice} onChange={(e) => this.handleInputChange(e, "productPrice")} />
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                    {
                                        this.state.productCategoryId < 5
                                            ?
                                            <AdminPanelTr>
                                                <AdminPanelTd>
                                                    Etykieta
                                                    <AdminPanelSeparator/>
                                                </AdminPanelTd>
                                                <AdminPanelTd>
                                                    <LegendIcon type="spicy" grayscale={!this.state.productIsSpicy}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => this.setState({productIsSpicy: !this.state.productIsSpicy})}/>
                                                    <LegendIcon type="vege" grayscale={!this.state.productIsVege}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => this.setState({productIsVege: !this.state.productIsVege})}/>
                                                </AdminPanelTd>
                                            </AdminPanelTr>
                                            :
                                            ""
                                    }
                                    {
                                        this.state.productCategoryId < 5
                                            ?
                                            <AdminPanelTr>
                                                <AdminPanelTd>
                                                    Zdjęcie
                                                    <AdminPanelSeparator />
                                                </AdminPanelTd>
                                                <AdminPanelTd>
                                                    {
                                                        this.state.productPhoto
                                                            ? <img width={128} src={config.backendServer + "/public/product_files/" + this.state.productPhoto} />
                                                            : ""
                                                    }
                                                    <Clearfix />
                                                    <input id="photo-file" type="file" onChange={() => this.setState({productPhoto: null})} />
                                                </AdminPanelTd>
                                            </AdminPanelTr>
                                            :
                                            ["KOKTAJLE KLASYCZNE", "KOKTAJLE BEZ ALKOHOLU", "DOMOWE LEMONIADY", "SPECJAŁY NASZEGO BARU"].includes(this.state.productTag)
                                            ?
                                                <AdminPanelTr>
                                                    <AdminPanelTd>
                                                        Ikona
                                                        <AdminPanelSeparator />
                                                    </AdminPanelTd>
                                                    <AdminPanelTd>
                                                        {/*{*/}
                                                        {/*    this.state.productIcon*/}
                                                        {/*        ? <img width={128} src={config.backendServer + "/public/product_files/" + this.state.productIcon} />*/}
                                                        {/*        : ""*/}
                                                        {/*}*/}

                                                        {
                                                            [0, 1, 2, 3, 4, 5, 6, 7, 8].map(
                                                                (iconIndex) => (
                                                                    <DrinkIcon onClick={() => this.setState({productIcon: iconIndex})} style={{margin: "0 6px", cursor: "pointer"}} iconIndex={iconIndex} grayscale={iconIndex !== parseInt(this.state.productIcon)} />
                                                                )
                                                            )
                                                        }

                                                        <Clearfix />
                                                        {/*<input id="icon-file" type="file" onChange={() => this.setState({productIcon: null})} />*/}
                                                    </AdminPanelTd>
                                                </AdminPanelTr>
                                            :
                                                <AdminPanelTr>
                                                    <AdminPanelTd>
                                                        Ikona
                                                        <AdminPanelSeparator />
                                                    </AdminPanelTd>
                                                    <AdminPanelTd>
                                                        Przedmioty z tego tagu nie zawierają ikon
                                                    </AdminPanelTd>
                                                </AdminPanelTr>
                                    }
                                    <AdminPanelTr>
                                        <AdminPanelTd>
                                            Priorytet pozycji
                                            <Clearfix />
                                            <span style={{fontSize: 12}}>Im wyższy priorytet, tym wyżej produkt będzie pokazany</span>
                                            <AdminPanelSeparator />
                                        </AdminPanelTd>
                                        <AdminPanelTd>
                                            <InputText type="number" style={{width: "100%", margin: "0 0"}} placeholder="Priorytet pozycji" value={this.state.productPositionPriority} onChange={(e) => this.handleInputChange(e, "productPositionPriority")} />
                                        </AdminPanelTd>
                                    </AdminPanelTr>
                                </AdminPanelTable>
                            )
                        :
                            <div>
                                <AdminPanelTable style={{minWidth: 500}}>
                                    <AdminPanelTr>
                                        <AdminPanelTh>Wiadomość zwrotna</AdminPanelTh>
                                    </AdminPanelTr>
                                    <AdminPanelTr>
                                        <AdminPanelTd>{this.state.feedbackMessage}</AdminPanelTd>
                                    </AdminPanelTr>
                                </AdminPanelTable>
                            </div>
                    }


                    <div style={{marginBottom: 60}}>
                        {
                            !this.state.isFeedback
                            ?
                                <CTA style={{float: "right", marginTop: "60px"}} noMargins onClick={() => this.save()}>ZAPISZ</CTA>
                            :
                                <div style={{width: 700}}>
                                    <CTA style={{float: "left", marginTop: "60px", marginRight: 30}} noMargins onClick={() => this.props.setSection(0)}>DODAJ KOLEJNY PRODUKT</CTA>
                                    <CTA style={{float: "right", marginTop: "60px"}} noMargins onClick={() => this.props.setSection(1)}>PRZEJDŹ DO LISTY</CTA>
                                </div>
                        }

                        <Clearfix />
                    </div>
                </div>
            </Container>
        )
    }
}

export default AdminAddProductSection;