import config from '../../config.json';

class IsAdminCheck {
    static check() {
        let token = localStorage.getItem('token');
        return new Promise(function(resolve, reject) {
            //fetch(`${config.backendServer}/api/v1/is_admin`, {method: "GET", credentials: 'include'}) // <- for cookies
            fetch(`${config.backendServer}/api/v1/is_admin?token=${token}`, {method: "GET"})
                .then(res => res.json())
                .then(res => {
                    if (res.status && res.isAdmin) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
        });
    }
}

export default IsAdminCheck;