import styled from "styled-components";
import LogoImg from "../../Admin/AdminSidebar/Logo.png";
import ArrowRightImg from "./Arrow-right.png";

const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  padding: 20px 36px;
  padding-right: calc(30px + 24px);
  font-size: 18px;
  color: white;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #146E55;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  position: relative;
  width: auto;
  transition: 0.3s;
  float: left;
  

  margin: ${props => props.margins ? "0 10px" : "0 0"};
  
  &:hover {
    padding-right: calc(30px + 24px + 12px);
    background-color: #075C48;
  }

  @media (min-width: 701px) and (max-width: 1100px) {
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
    padding-right: calc(30px + 16px);
    padding-left: -4px;
    &:hover {
      padding-right: calc(30px + 16px);
      
      background-color: #075C48;
    }
  }
`;

const ArrowRight = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 26px;

  @media (max-width: 700px) {
    right: 18px;
  }
  
`;

const CTA = props => {
    return(
        <Container margins={!props.noMargins} style={props.style} onClick={props.onClick}>
            {
                props.children
            }
            <ArrowRight src={ArrowRightImg} />
        </Container>
    );
}

export default CTA;